import { Empty } from "antd"
import { ChangeEvent, PropsWithChildren, useState } from "react"
import { SelectedExpectation } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { FilterExpectations } from "src/Expectation/CreateExpectationDrawer/FilterExpectations"
import { SearchExpectations } from "src/Expectation/CreateExpectationDrawer/SearchExpectations"
import { PickerHeaderWithFilterOptions } from "src/Expectation/CreateExpectationDrawer/PickerHeaderWithFilterOptions"
import { RadioCardGroup } from "src/ui/Radio/RadioCardGroup"
import { useFilteredExpectationsCards } from "src/Expectation/CreateExpectationDrawer/useFilteredExpectationsCards"
import { EXPECTATION_PICKER_EMPTY_STATE, EXPECTATIONS_QUERY_ERROR } from "src/Expectation/CreateExpectationDrawer/words"

type Props = { onSelectExpectation: (expectation: SelectedExpectation) => void }

function ExpectationsQueryErrorHandler(props: PropsWithChildren<{ error: boolean; title: string }>) {
  if (props.error) {
    return <FeedbackMessageDoNotUse title={props.title} type="error" />
  }
  return <>{props.children}</>
}

export function ExpectationPickerSimplified({ onSelectExpectation }: Props) {
  const [searchInput, setSearchInput] = useState<string>("")
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<string | undefined>(undefined)

  const { filteredExpectationsCardsData } = useFilteredExpectationsCards({
    searchInput,
    selectedCategoryFilter,
    onSelectExpectation,
  })

  return (
    <PickerHeaderWithFilterOptions
      searchOptions={
        <SearchExpectations
          value={searchInput}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setSearchInput(e.target.value)
          }}
        />
      }
      filterOptions={
        <FilterExpectations
          value={selectedCategoryFilter}
          onSelect={(value: string) => {
            setSelectedCategoryFilter(value)
          }}
        />
      }
    >
      <ExpectationsQueryErrorHandler title={EXPECTATIONS_QUERY_ERROR} error={false}>
        <RadioCardGroup
          cards={filteredExpectationsCardsData}
          emptyState={{
            image: Empty.PRESENTED_IMAGE_SIMPLE,
            description: EXPECTATION_PICKER_EMPTY_STATE,
          }}
        />
      </ExpectationsQueryErrorHandler>
    </PickerHeaderWithFilterOptions>
  )
}
