import { cloneDeep } from "lodash-es"
import { ExpectationJsonSchema } from "src/Expectation/uiForms/ExpectationConfigForm"

// Add this type to make properties mutable
type MutableExpectationJsonSchema = Omit<ExpectationJsonSchema, "properties"> & {
  properties: Record<string, unknown>
}

export const getJsonSchemaWithWindows = (jsonSchema: ExpectationJsonSchema): ExpectationJsonSchema => {
  const clonedJsonSchema = cloneDeep(jsonSchema) as MutableExpectationJsonSchema

  switch (clonedJsonSchema.title) {
    case "Expect column minimum to be between":
    case "Expect column maximum to be between":
    case "Expect column mean to be between":
    case "Expect column median to be between":
    case "Expect column sum to be between":
    case "Expect column standard deviation to be between":
    case "Expect column unique value count to be between":
    case "Expect column proportion of unique values to be between":
    case "Expect table row count to be between":
    case "Expect table column count to be between":
      clonedJsonSchema.properties = {
        ...clonedJsonSchema.properties,
        _condition: {
          type: "string",
          title: "to be between",
          enum: ["fixed values", "dynamic range"],
        },
        _percent_offset: {
          type: "string",
          title: "",
          enum: ["+", "-", "+/-"],
          default: "+/-",
        },
        _percent: {
          type: "number",
          title: "",
        },
        _range: {
          type: "number",
          title: "of the average of the last",
        },
        _strict: {
          type: "boolean",
          title: "Strict",
        },
      }
      break
    case "Expect column values to be null":
    case "Expect column values to not be null":
      clonedJsonSchema.properties = {
        ...clonedJsonSchema.properties,
        _condition: {
          type: "string",
          title: "to have at least",
          enum: ["a fixed %", "a dynamic %"],
        },
        _percent: {
          type: "number",
          title: "",
        },
        _range: {
          type: "number",
          title: "of the average of the last",
        },
      }
      break
  }

  return clonedJsonSchema as ExpectationJsonSchema
}
