/* eslint-disable react-refresh/only-export-components */ // FIXME
import { ControlProps, JsonFormsRendererRegistryEntry, rankWith, scopeEndsWith } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"
import { CodeSnippetEditor } from "src/ui/CodeSnippetEditor/CodeSnippetEditor.tsx"
import { Form } from "antd"

export const SQLEditorRegistryEntry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(
    100, //increase rank as needed
    scopeEndsWith("#/properties/unexpected_rows_query"),
  ),
  renderer: withJsonFormsControlProps(SQLEditorControl),
}

function SQLEditorControl({ data, path, required, uischema, handleChange }: ControlProps) {
  const initalValue = data ?? uischema?.options?.placeholderText
  return (
    <Form.Item
      name={path}
      initialValue={initalValue}
      rules={[
        { required, message: "SQL Query is required" },
        {
          async validator(_, value) {
            if (value && value === uischema?.options?.placeholderText) {
              return Promise.reject("SQL Query is required")
            }
            return Promise.resolve()
          },
        },
      ]}
    >
      <CodeSnippetEditor
        language="sql"
        value={initalValue}
        name={path}
        placeholder={uischema?.options?.placeholderText}
        readOnly={false}
        fontSize={14}
        minLines={9}
        maxLines={15}
        showLineNumbers={false}
        showGutter={false}
        onChange={(val) => handleChange(path, val)}
      />
    </Form.Item>
  )
}
