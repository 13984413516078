import { Flex, Form, Input } from "antd"
import { useState } from "react"
import { useAnalytics } from "src/analytics/useAnalytics"
import { AppModal } from "src/ui/AppModal/AppModal"
import { Button } from "src/ui/Button/Button"
import { Icon } from "src/ui/Icon/Icon"
import { theme } from "src/ui/themes/theme"
import { BodyRegular } from "src/ui/typography/Text/Text"

const FORM_ID = "missing_expectation_survey_sept2024"
const FEEDBACK_FIELDNAME = "feedback"

interface CreateExpectationFeedbackModalProps {
  step: number
  setStep: (step: number) => void
}

export function CreateExpectationFeedbackModal(props: CreateExpectationFeedbackModalProps): JSX.Element {
  const { step, setStep } = props
  const [form] = Form.useForm()
  const analytics = useAnalytics()

  const onFinish = () => {
    analytics?.capture("survey.submitted", {
      survey_name: "missing_expectation_survey_sept2024",
      survey_id: "fe21f957-8d05-4b88-97e9-316e9b1517d5",
      survey_response: {
        message: form.getFieldValue(FEEDBACK_FIELDNAME),
      },
    })

    setStep(2)
  }

  const reset = () => {
    form.resetFields()
    setStep(0)
  }

  const step1Body = (
    <Form id={FORM_ID} layout="vertical" form={form} onFinish={() => onFinish()}>
      <Form.Item required label="Describe the test you are looking for" name={FEEDBACK_FIELDNAME}>
        <Input.TextArea required />
      </Form.Item>
    </Form>
  )

  const step1Footer = (
    <Flex justify="center">
      <Button htmlType="submit" type="primary" form={FORM_ID}>
        Submit
      </Button>
    </Flex>
  )

  const step2Body = (
    <Flex align="center" vertical>
      <Icon color={theme.colors.success.gxSuccess} name="checkCircleSolid" title="Success" />
      <BodyRegular>Thanks for your feedback!</BodyRegular>
      <BodyRegular>Our product team will reach out to you shortly.</BodyRegular>
    </Flex>
  )

  const step2Footer = (
    <Flex justify="center">
      <Button type="primary" onClick={() => reset()}>
        Ok
      </Button>
    </Flex>
  )

  return (
    <AppModal
      centered
      open={Boolean(step)}
      onCancel={() => reset()}
      destroyOnClose
      footer={
        <>
          {step === 1 && step1Footer}
          {step === 2 && step2Footer}
        </>
      }
    >
      <>
        {step === 1 && step1Body}
        {step === 2 && step2Body}
      </>
    </AppModal>
  )
}

export function CreateExpectationFeedbackButton(): JSX.Element {
  // 0 = closed, 1 = form, 2 = confirmation
  const [step, setStep] = useState(0)

  return (
    <>
      <Button icon="commentQuestion" onClick={() => setStep(1)}>
        Need a different Expectation?
      </Button>
      <CreateExpectationFeedbackModal step={step} setStep={setStep} />
    </>
  )
}
