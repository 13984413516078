/* eslint-disable react-refresh/only-export-components */ // FIXME
import { LabelRegular } from "src/ui/typography/Text"
import styled from "styled-components"
import { graphql } from "src/api/graphql/gql"
import { Maybe } from "src/api/graphql/graphql"
import { getImageLogo } from "src/DataAssets/getImageLogo"
import { FragmentType, unmaskFragment } from "src/api/graphql/fragment-masking"
import { Icon, IconTypes } from "src/ui/Icon/Icon"
import { theme } from "src/ui/themes/theme"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { Tooltip, TooltipProps } from "antd"
import { parseJSONStringConfig } from "src/DataAssets/AssetDetails/utils"
import { getDatabaseAndSchemaFromDataSource } from "src/DataAssets/connect-to-data/select-table-names/get-address-from-data-source"
import { SupportedDataSourceJson } from "src/DataAssets/connect-to-data/schemas/data-source-schemas"

const StyledLogo = styled.img`
  width: 12px;
  margin-right: ${({ theme }) => theme.spacing.horizontal.xxs};
  vertical-align: middle;
`

const StyledLabel = styled(LabelRegular)`
  word-break: "break-word";
  color: ${({ theme }) => theme.colors.neutralColorPalette.blacks.colorTextTertiary};
  vertical-align: middle;
`

export const DataAssetBreadcrumbs_DataSourceFragmentDocument = graphql(`
  fragment DataAssetBreadcrumbs_DataSource on DatasourceV2 {
    name
    type
    config
  }
`)
interface DataAssetCustomBreadcrumbProps {
  dataSource: Maybe<FragmentType<typeof DataAssetBreadcrumbs_DataSourceFragmentDocument>> | undefined
  showDataSourceLogo?: boolean
  tooltipPlacement?: TooltipProps["placement"]
}

export const getDataAssetBreadcrumbs = ({
  dataSource: maskedDataSource,
  showDataSourceLogo = true,
  tooltipPlacement = "bottom",
}: DataAssetCustomBreadcrumbProps): HeaderTypes["customBreadcrumbs"] => {
  const dataSource = unmaskFragment(DataAssetBreadcrumbs_DataSourceFragmentDocument, maskedDataSource)
  const dataSourceJsonConfig = parseJSONStringConfig(dataSource?.config ?? "{}") // should probably validate this against json schemas
  const dbAndSchemaItems = getDatabaseAndSchemaFromDataSource(dataSourceJsonConfig as SupportedDataSourceJson)
  const items = [
    {
      title: (
        <Tooltip title="Data Source name" placement={tooltipPlacement}>
          {showDataSourceLogo && dataSource?.type && (
            <StyledLogo src={getImageLogo(dataSource.type)} alt={`${dataSource.type} logo`} />
          )}
          <StyledLabel>{dataSource?.name}</StyledLabel>
        </Tooltip>
      ),
    },
    ...(dbAndSchemaItems?.database
      ? [
          {
            title: (
              <DataAssetBreadcrumb
                label={dbAndSchemaItems.database}
                iconType="database"
                tooltipTitle="Database"
                tooltipPlacement={tooltipPlacement}
              />
            ),
          },
        ]
      : []),
    ...(dbAndSchemaItems?.schema
      ? [
          {
            title: (
              <DataAssetBreadcrumb
                label={dbAndSchemaItems.schema}
                iconType="schema"
                tooltipTitle="Schema"
                tooltipPlacement={tooltipPlacement}
              />
            ),
          },
        ]
      : []),
  ]

  return items
}

interface BreadcrumbProps {
  iconType: IconTypes
  label: string
  tooltipTitle: string
  tooltipPlacement?: TooltipProps["placement"]
}

const DataAssetBreadcrumb = ({ iconType, label, tooltipTitle, tooltipPlacement = "bottom" }: BreadcrumbProps) => {
  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement}>
      <Icon
        name={iconType}
        paddingRight={theme.spacing.horizontal.xxs}
        size="12px"
        color={theme.colors.neutralColorPalette.blacks.colorTextTertiary}
        style={{ verticalAlign: "middle" }}
      />
      <StyledLabel>{label}</StyledLabel>
    </Tooltip>
  )
}
