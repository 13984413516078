import { useState } from "react"
import { useQuery } from "@apollo/client"
import { Flex, Table, Tag } from "antd"
import type { TableProps } from "antd"
import { useParams } from "react-router-dom"
import { uniq } from "lodash-es"

import { graphql } from "src/api/graphql/gql"
import { PageHeader } from "src/ui/PageHeader/PageHeader"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { MainContent } from "src/layout/MainContent"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { GetExpectationsQuery, ExpectationValidationResultV2, Domain } from "src/api/graphql/graphql"
import { theme } from "src/ui/themes/theme"
import { Button } from "src/ui/Button/Button"
import { SimpleExpectationDrawer } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer"
import { assertNever } from "src/DataAssets/utils"
import { getRenderer } from "src/Expectation/utils"

export const ExpectationsTabDataAssetDocument = graphql(`
  query ExpectationsTabDataAsset($id: UUID!) {
    dataAsset(id: $id) {
      name
      datasourceV2 {
        ...DataAssetBreadcrumbs_DataSource
        name
        type
        config
      }
    }
  }
`)

export const getExpectationsDocument = graphql(`
  query GetExpectations($input: GetExpectationsInput!) {
    getExpectations(input: $input) {
      domain {
        domainKwargs
        domainType
        id
        columns {
          name
          order
        }
      }
      geCloudId
      kwargTypes
      kwargs
      expectationType
      renderedContent {
        ...RenderedExpectation
      }
      validationResults(input: { limit: 1 }) {
        runTime
        success
      }
    }
  }
`)

export const SimpleExpectationsTab = () => {
  const { assetId = "6213206a-af80-4591-9be1-73044509e2a1" } = useParams<{
    assetId: string
  }>()

  const { data: dataAssetData, loading: dataAssetLoading } = useQuery(ExpectationsTabDataAssetDocument, {
    variables: {
      id: assetId,
    },
    skip: !assetId,
  })
  const { data: expectationsData, loading: expectationsLoading } = useQuery(getExpectationsDocument, {
    variables: {
      input: { dataAssetId: assetId },
    },
    skip: !assetId,
  })

  const dataAssetName = dataAssetData?.dataAsset?.name ?? ""

  return (
    <PageHeader
      headerContent={{
        title: dataAssetName,
        rootPath: "data-assets",
        navigateBackTo: "/data-assets",
        customBreadcrumbs: getDataAssetBreadcrumbs({
          dataSource: dataAssetData?.dataAsset?.datasourceV2,
          tooltipPlacement: "right",
        }),
        footer: <DataAssetTabs currentTab="expectations" />,
      }}
      loading={expectationsLoading || dataAssetLoading}
    >
      <MainContent $noIllustration={true}>
        <Table
          title={() => <ExpectationsTableHeader dataAssetId={assetId} />}
          id="simple-expectations-table"
          columns={getColumns(expectationsData)}
          dataSource={getExpectationRowData(expectationsData)}
          loading={expectationsLoading}
          rowKey={({ domain }) => domain.id}
        />
      </MainContent>
    </PageHeader>
  )
}

function ExpectationsTableHeader({ dataAssetId }: { dataAssetId: string }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Flex justify="flex-end">
      <Button type="primary" onClick={() => setIsOpen(true)}>
        + New Expectation
      </Button>
      <SimpleExpectationDrawer dataAssetId={dataAssetId} open={isOpen} close={() => setIsOpen(false)} />
    </Flex>
  )
}

type DomainData = {
  name: string
  id: string
}

interface DataType {
  domain: DomainData
  expectation: string | JSX.Element | null
  lastRun: Omit<ExpectationValidationResultV2, "__typename">
  actions: string
}

const getColumns = (data: GetExpectationsQuery | undefined): TableProps<DataType>["columns"] => [
  {
    title: "Domain",
    dataIndex: "domain",
    key: "domain",
    filters: getDomainFilter(data),
    onFilter: (value, record) => record.domain.name === value,
    filterMultiple: true,
    render: (_, { domain }) => domain.name,
  },
  {
    title: "Expectation",
    dataIndex: "expectation",
    key: "exp",
    width: "55%",
  },
  {
    title: "Last run",
    dataIndex: "lastRun",
    key: "run",
    render: (_, { lastRun }) => {
      const color = lastRun.success ? theme.colors.success.gxSuccess : theme.colors.error.gxError
      return (
        <span>
          <Tag color={color}>{lastRun.success ? "Pass" : "Fail"}</Tag> {lastRun.runTime}
        </span>
      )
    },
  },
  {
    title: "Actions",
    dataIndex: "actions",
    key: "actions",
  },
]

const getExpectationRowData = (data: GetExpectationsQuery | undefined): DataType[] => {
  if (!data?.getExpectations) {
    return []
  }

  return data?.getExpectations?.map((exp) => {
    return {
      domain: { name: exp?.domain ? getDomainColumn(exp?.domain) : "", id: exp?.domain?.id ?? "" },
      expectation:
        getRenderer({ renderedValue: exp?.renderedContent?.[0], fallback: exp?.expectationType ?? undefined }) ?? null,
      lastRun: {
        success: exp?.validationResults?.[0]?.success ?? undefined,
        runTime: exp?.validationResults?.[0]?.runTime ?? "Unknown",
      },
      actions: "",
    }
  })
}

const getDomainColumn = (domain: Domain): string => {
  switch (domain.domainType) {
    case "COLUMN":
      return domain?.columns?.[0]?.name ?? ""
    case "COLUMN_PAIR":
    case "MULTICOLUMN":
      return domain.columns?.map((col) => col.name).join(", ") ?? ""
    case "TABLE":
      return "Table"
    default:
      return domain.domainType ? assertNever(domain.domainType) : ""
  }
}

const getDomainFilter = (data: GetExpectationsQuery | undefined) => {
  if (!data?.getExpectations) {
    return []
  }

  const filterList = data?.getExpectations?.map((exp) => {
    return exp?.domain ? getDomainColumn(exp?.domain) : ""
  })

  return uniq(filterList).map((filter) => ({ text: filter, value: filter }))
}
