import { JsonFormsUISchemaRegistryEntry, UISchemaElement } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { Icon } from "src/ui/Icon"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { baseUISchemaElements } from "src/Expectation/uiForms/schemas/base-ui-schema-elements"
import {
  expectWindowedColumnMaxToBeBetween,
  expectWindowedColumnMeanToBeBetween,
  expectWindowedColumnMinToBeBetween,
  expectWindowedColumnMedianToBeBetween,
  expectWindowedColumnSumToBeBetween,
  expectWindowedColumnStdevToBeBetween,
  expectWindowedColumnUniqueValueCountToBeBetween,
  expectWindowedColumnProportionOfUniqueValuesToBeBetween,
  expectWindowedColumnValuesToBeNull,
  expectWindowedColumnValuesToNotBeNull,
} from "src/Expectation/uiForms/schemas/windowed-expectation-catalog-ui-schemas"

export const ValueSetUISchemaRegistryEntry: JsonFormsUISchemaRegistryEntry = {
  tester: (schema, schemaPath, path) => {
    if (schemaPath === "#/properties/value_set" && path === "value_set" && schema.type === "array") {
      return 100
    }
    return -1
  },
  uischema: {
    type: "Control",
    scope: "#",
    options: {
      addButtonProps: {
        children: "+ Add value",
      },
      removeButtonProps: {
        type: "text",
        icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
        children: "",
      },
      showSortButtons: true,
      moveUpButtonProps: {
        icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
      },
      moveDownButtonProps: {
        icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
      },
    },
  } satisfies UISchema<
    (typeof jsonSchemas.expectColumnValuesToBeInSet.schema.properties.value_set.oneOf)[0]
  > as UISchemaElement,
}

const expectColumnMaxToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMaxToBeBetween.schema>

const expectColumnMeanToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMeanToBeBetween.schema>

const expectColumnMinToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMinToBeBetween.schema>

const expectColumnMedianToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMedianToBeBetween.schema>

const expectColumnValuesToBeInSet = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeInSet.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/value_set",
      options: {
        optionType: "segmented",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeInSet.schema.properties.value_set.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeInSet.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeInSet.schema>

const expectColumnValuesToBeInTypeList = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeInTypeList.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/type_list",
      options: {
        addButtonProps: {
          children: "+ Add type",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeInTypeList.schema.properties.type_list.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeInTypeList.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeInTypeList.schema>

const expectColumnValuesToBeNull = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeNull.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeNull.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeNull.schema>

const expectColumnValuesToNotBeNull = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotBeNull.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotBeNull.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotBeNull.schema>

const expectColumnValuesToBeOfType = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeOfType.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/type_",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeOfType.schema.properties.type_.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeOfType.schema.properties.mostly.description,
      },
      options: {
        addonAfter: "%",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeOfType.schema>

const expectColumnValuesToBeUnique = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeUnique.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeUnique.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeUnique.schema>

const expectTableColumnsToMatchOrderedList = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column_list",
      options: {
        addButtonProps: {
          children: "+ Add column",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectTableColumnsToMatchOrderedList.schema.properties.column_list.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableColumnsToMatchOrderedList.schema>

const expectTableRowCountToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/min_value",
      formItemProps: {
        tooltip: jsonSchemas.expectTableRowCountToBeBetween.schema.properties.min_value.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/max_value",
      formItemProps: {
        tooltip: jsonSchemas.expectTableRowCountToBeBetween.schema.properties.max_value.description,
      },
    },
    ...baseUISchemaElements,
  ],
}

const expectTableRowCountToEqual = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/value",
      formItemProps: {
        tooltip: jsonSchemas.expectTableRowCountToEqual.schema.properties.value.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableRowCountToEqual.schema>

const expectColumnToExist = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
    },
    {
      type: "Control",
      scope: "#/properties/column_index",
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnToExist.schema>

const expectColumnPairValuesToBeEqual = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column_A",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnPairValuesToBeEqual.schema.properties.column_A.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/column_B",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnPairValuesToBeEqual.schema.properties.column_B.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnPairValuesToBeEqual.schema.properties.mostly.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/ignore_row_if",
      options: {
        enumValueToLabelMap: {
          both_values_are_missing: "Both values are missing",
          either_value_is_missing: "Either value is missing",
          neither: "Neither",
        },
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnPairValuesToBeEqual.schema>

const expectColumnPairValuesAToBeGreaterThanB = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "VerticalLayout",
          elements: [
            {
              type: "Control",
              scope: "#/properties/column_A",
              formItemProps: {
                tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.column_A.description,
              },
            },
            {
              type: "Control",
              scope: "#/properties/column_B",
              formItemProps: {
                tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.column_B.description,
              },
            },
          ],
        },
        {
          type: "Control",
          scope: "#/properties/or_equal",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.or_equal.description,
          },
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema.properties.mostly.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/ignore_row_if",
      options: {
        enumValueToLabelMap: {
          both_values_are_missing: "Both values are missing",
          either_value_is_missing: "Either value is missing",
          neither: "Neither",
        },
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnPairValuesAToBeGreaterThanB.schema>

const expectMulticolumnSumToEqual = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column_list",
      options: {
        addButtonProps: {
          children: "+ Add column",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.column_list.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/sum_total",
      formItemProps: {
        tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.sum_total.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.mostly.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/ignore_row_if",
      options: {
        enumValueToLabelMap: {
          all_values_are_missing: "All values are missing",
          any_value_is_missing: "Any value is missing",
          never: "Never",
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectMulticolumnSumToEqual.schema.properties.ignore_row_if.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectMulticolumnSumToEqual.schema>

const expectCompoundColumnsToBeUnique = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column_list",
      options: {
        addButtonProps: {
          children: "+ Add column",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectCompoundColumnsToBeUnique.schema.properties.column_list.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectCompoundColumnsToBeUnique.schema.properties.mostly.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/ignore_row_if",
      options: {
        enumValueToLabelMap: {
          all_values_are_missing: "All values are missing",
          any_value_is_missing: "Any value is missing",
          never: "Never",
        },
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectCompoundColumnsToBeUnique.schema>

const expectSelectColumnValuesToBeUniqueWithinRecord = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column_list",
      options: {
        addButtonProps: {
          children: "+ Add column",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectSelectColumnValuesToBeUniqueWithinRecord.schema.properties.column_list.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectSelectColumnValuesToBeUniqueWithinRecord.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectSelectColumnValuesToBeUniqueWithinRecord.schema>

const expectTableColumnCountToEqual = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/value",
      formItemProps: {
        tooltip: jsonSchemas.expectTableColumnCountToEqual.schema.properties.value.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableColumnCountToEqual.schema>

const expectTableColumnsToMatchSet = {
  type: "VerticalLayout",
  elements: [
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/column_set",
          options: {
            addButtonProps: {
              children: "+ Add column",
            },
            removeButtonProps: {
              type: "text",
              icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
              children: "",
            },
            showSortButtons: true,
            moveUpButtonProps: {
              icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
            },
            moveDownButtonProps: {
              icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
            },
          },
          formItemProps: {
            tooltip: jsonSchemas.expectTableColumnsToMatchSet.schema.properties.column_set.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/exact_match",
          formItemProps: {
            tooltip: jsonSchemas.expectTableColumnsToMatchSet.schema.properties.exact_match.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableColumnsToMatchSet.schema>

const expectTableColumnCountToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/min_value",
      formItemProps: {
        tooltip: jsonSchemas.expectTableColumnCountToBeBetween.schema.properties.min_value.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/max_value",
      formItemProps: {
        tooltip: jsonSchemas.expectTableColumnCountToBeBetween.schema.properties.max_value.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableColumnCountToBeBetween.schema>

const expectTableRowCountToEqualOtherTable = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/other_table_name",
      formItemProps: {
        tooltip: jsonSchemas.expectTableRowCountToEqualOtherTable.schema.properties.other_table_name.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectTableRowCountToEqualOtherTable.schema>

const expectColumnValueLengthsToEqual = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValueLengthsToEqual.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/value",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValueLengthsToEqual.schema.properties.value.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValueLengthsToEqual.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValueLengthsToEqual.schema>

const expectColumnValuesToMatchLikePattern = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchLikePattern.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/like_pattern",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchLikePattern.schema.properties.like_pattern.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchLikePattern.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToMatchLikePattern.schema>

const expectColumnValuesToMatchLikePatternList = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/like_pattern_list",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.like_pattern_list.description,
      },
      options: {
        addButtonProps: {
          children: "+ Add like pattern",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/match_on",
      options: {
        optionType: "segmented",
        enumValueToLabelMap: {
          any: "Any",
          all: "All",
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.match_on.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchLikePatternList.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToMatchLikePatternList.schema>

const expectColumnValuesToMatchRegex = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchRegex.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/regex",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchRegex.schema.properties.regex.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchRegex.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToMatchRegex.schema>

const expectColumnValuesToMatchRegexList = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/regex_list",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.regex_list.description,
      },
      options: {
        addButtonProps: {
          children: "+ Add regex",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/match_on",
      options: {
        optionType: "segmented",
        enumValueToLabelMap: {
          any: "Any",
          all: "All",
        },
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.match_on.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToMatchRegexList.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToMatchRegexList.schema>

const expectColumnValuesToNotMatchLikePattern = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/like_pattern",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema.properties.like_pattern.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotMatchLikePattern.schema>

const expectColumnValuesToNotMatchLikePatternList = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/like_pattern_list",
      formItemProps: {
        tooltip:
          jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema.properties.like_pattern_list.description,
      },
      options: {
        addButtonProps: {
          children: "+ Add like pattern",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotMatchLikePatternList.schema>

const expectColumnValuesToNotMatchRegex = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchRegex.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/regex",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchRegex.schema.properties.regex.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchRegex.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotMatchRegex.schema>

const expectColumnValuesToNotMatchRegexList = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchRegexList.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/regex_list",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchRegexList.schema.properties.regex_list.description,
      },
      options: {
        addButtonProps: {
          children: "+ Add regex",
        },
        removeButtonProps: {
          type: "text",
          icon: <Icon name="trash" size="16px" style={{ marginTop: "3px" }} />,
          children: "",
        },
        showSortButtons: true,
        moveUpButtonProps: {
          icon: <Icon name="arrowUp" size="16px" style={{ marginTop: "3px" }} />,
        },
        moveDownButtonProps: {
          icon: <Icon name="arrowDown" size="16px" style={{ marginTop: "3px" }} />,
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotMatchRegexList.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotMatchRegexList.schema>

const expectColumnValuesToNotBeInSet = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotBeInSet.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/value_set",
      options: {
        optionType: "segmented",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotBeInSet.schema.properties.value_set.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotBeInSet.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotBeInSet.schema>

const expectColumnValuesToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeBetween.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeBetween.schema>

const expectColumnValueZScoresToBeLessThan = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/threshold",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.threshold.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/double_sided",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.double_sided.description,
          },
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValueZScoresToBeLessThan.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValueZScoresToBeLessThan.schema>

const expectColumnValueLengthsToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "%",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValueLengthsToBeBetween.schema.properties.mostly.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValueLengthsToBeBetween.schema>

const expectColumnDistinctValuesToBeInSet = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnDistinctValuesToBeInSet.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/value_set",
      options: {
        optionType: "segmented",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnDistinctValuesToBeInSet.schema.properties.value_set.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnDistinctValuesToBeInSet.schema>

const expectColumnDistinctValuesToContainSet = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnDistinctValuesToContainSet.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/value_set",
      options: {
        optionType: "segmented",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnDistinctValuesToContainSet.schema.properties.value_set.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnDistinctValuesToContainSet.schema>

const expectColumnDistinctValuesToEqualSet = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnDistinctValuesToEqualSet.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/value_set",
      options: {
        optionType: "segmented",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnDistinctValuesToEqualSet.schema.properties.value_set.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnDistinctValuesToEqualSet.schema>

const expectColumnMostCommonValueToBeInSet = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMostCommonValueToBeInSet.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/value_set",
      options: {
        optionType: "segmented",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMostCommonValueToBeInSet.schema.properties.value_set.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/ties_okay",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMostCommonValueToBeInSet.schema.properties.ties_okay.description,
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMostCommonValueToBeInSet.schema>

const expectColumnProportionOfUniqueValuesToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema>

const expectColumnStdevToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnStdevToBeBetween.schema>

const expectColumnSumToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnSumToBeBetween.schema>

const expectColumnUniqueValueCountToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.column.description,
      },
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.min_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.strict_min.description,
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.max_value.description,
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.strict_max.description,
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema>

export const UNEXPECTED_ROWS_DOCS_LINK =
  "https://docs.greatexpectations.io/docs/core/customize_expectations/use_sql_to_define_a_custom_expectation/"

const unexpectedRowsExpectation = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/description",
      label: {
        text: "Expectation description",
      },
      options: {
        placeholderText: jsonSchemas.unexpectedRowsExpectation.schema.properties.description.description,
        required: true,
      },
    },
    {
      type: "Control",
      scope: "#/properties/unexpected_rows_query",
      options: {
        required: true,
        placeholderText: `-- Example query that checks that all sedan rides have no more than 4 passengers

SELECT
  *
FROM
  {batch}
WHERE
  vehicle_type = 'sedan'
  AND passenger_count > 4;`,
      },
    },
    {
      type: "Label",
      text: `\`{batch}\` is an optional named query that represents the data you have selected from the Data Asset. GX Cloud will automatically replace \`{batch}\` with the necessary SQL.  For more information view [Use SQL to define a custom Expectation](${UNEXPECTED_ROWS_DOCS_LINK} "Use SQL to define a custom Expectation").`,
      options: {
        type: "info",
      },
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.unexpectedRowsExpectation.schema>

export type SupportedExpectation = Extract<
  keyof typeof jsonSchemas,
  | "expectColumnDistinctValuesToBeInSet"
  | "expectColumnDistinctValuesToContainSet"
  | "expectColumnDistinctValuesToEqualSet"
  | "expectColumnMaxToBeBetween"
  | "expectColumnMeanToBeBetween"
  | "expectColumnMedianToBeBetween"
  | "expectColumnMinToBeBetween"
  | "expectColumnMostCommonValueToBeInSet"
  | "expectColumnPairValuesAToBeGreaterThanB"
  | "expectColumnPairValuesToBeEqual"
  | "expectColumnProportionOfUniqueValuesToBeBetween"
  | "expectColumnStdevToBeBetween"
  | "expectColumnSumToBeBetween"
  | "expectColumnToExist"
  | "expectColumnUniqueValueCountToBeBetween"
  | "expectColumnValueLengthsToBeBetween"
  | "expectColumnValueLengthsToEqual"
  | "expectColumnValuesToBeBetween"
  | "expectColumnValuesToBeInSet"
  | "expectColumnValuesToBeInTypeList"
  | "expectColumnValuesToBeNull"
  | "expectColumnValuesToBeOfType"
  | "expectColumnValuesToBeUnique"
  | "expectColumnValuesToMatchLikePattern"
  | "expectColumnValuesToMatchLikePatternList"
  | "expectColumnValuesToMatchRegex"
  | "expectColumnValuesToMatchRegexList"
  | "expectColumnValuesToNotBeInSet"
  | "expectColumnValuesToNotBeNull"
  | "expectColumnValuesToNotMatchLikePattern"
  | "expectColumnValuesToNotMatchLikePatternList"
  | "expectColumnValuesToNotMatchRegex"
  | "expectColumnValuesToNotMatchRegexList"
  | "expectColumnValueZScoresToBeLessThan"
  | "expectCompoundColumnsToBeUnique"
  | "expectMulticolumnSumToEqual"
  | "expectSelectColumnValuesToBeUniqueWithinRecord"
  | "expectTableColumnCountToBeBetween"
  | "expectTableColumnCountToEqual"
  | "expectTableColumnsToMatchOrderedList"
  | "expectTableColumnsToMatchSet"
  | "expectTableRowCountToBeBetween"
  | "expectTableRowCountToEqual"
  | "expectTableRowCountToEqualOtherTable"
  | "unexpectedRowsExpectation"
>

export const useUISchemaFromJSONSchema = (expectationClass: string) => {
  const windowedParamsEnabled = useIsFeatureEnabled("windowedParamsEnabled")
  const uiSchemas = {
    expectColumnDistinctValuesToBeInSet,
    expectColumnDistinctValuesToContainSet,
    expectColumnDistinctValuesToEqualSet,
    expectColumnMaxToBeBetween,
    expectColumnMeanToBeBetween,
    expectColumnMedianToBeBetween,
    expectColumnMinToBeBetween,
    expectColumnMostCommonValueToBeInSet,
    expectColumnPairValuesAToBeGreaterThanB,
    expectColumnPairValuesToBeEqual,
    expectColumnProportionOfUniqueValuesToBeBetween,
    expectColumnStdevToBeBetween,
    expectColumnSumToBeBetween,
    expectColumnToExist,
    expectColumnUniqueValueCountToBeBetween,
    expectColumnValueLengthsToBeBetween,
    expectColumnValueLengthsToEqual,
    expectColumnValuesToBeBetween,
    expectColumnValuesToBeInSet,
    expectColumnValuesToBeInTypeList,
    expectColumnValuesToBeNull,
    expectColumnValuesToBeOfType,
    expectColumnValuesToBeUnique,
    expectColumnValuesToMatchLikePattern,
    expectColumnValuesToMatchLikePatternList,
    expectColumnValuesToMatchRegex,
    expectColumnValuesToMatchRegexList,
    expectColumnValuesToNotBeInSet,
    expectColumnValuesToNotBeNull,
    expectColumnValuesToNotMatchLikePattern,
    expectColumnValuesToNotMatchLikePatternList,
    expectColumnValuesToNotMatchRegex,
    expectColumnValuesToNotMatchRegexList,
    expectColumnValueZScoresToBeLessThan,
    expectCompoundColumnsToBeUnique,
    expectMulticolumnSumToEqual,
    expectSelectColumnValuesToBeUniqueWithinRecord,
    expectTableColumnCountToBeBetween,
    expectTableColumnCountToEqual,
    expectTableColumnsToMatchOrderedList,
    expectTableColumnsToMatchSet,
    expectTableRowCountToBeBetween,
    expectTableRowCountToEqual,
    expectTableRowCountToEqualOtherTable,
    unexpectedRowsExpectation,
    // Override windowed expectations when enabled.
    // The key needs to match the camelCase expectation class.
    ...(windowedParamsEnabled && {
      expectColumnMinToBeBetween: expectWindowedColumnMinToBeBetween,
      expectColumnMaxToBeBetween: expectWindowedColumnMaxToBeBetween,
      expectColumnMeanToBeBetween: expectWindowedColumnMeanToBeBetween,
      expectColumnMedianToBeBetween: expectWindowedColumnMedianToBeBetween,
      expectColumnSumToBeBetween: expectWindowedColumnSumToBeBetween,
      expectColumnStdevToBeBetween: expectWindowedColumnStdevToBeBetween,
      expectColumnUniqueValueCountToBeBetween: expectWindowedColumnUniqueValueCountToBeBetween,
      expectColumnProportionOfUniqueValuesToBeBetween: expectWindowedColumnProportionOfUniqueValuesToBeBetween,
      expectColumnValuesToBeNull: expectWindowedColumnValuesToBeNull,
      expectColumnValuesToNotBeNull: expectWindowedColumnValuesToNotBeNull,
    }),
  } satisfies Record<SupportedExpectation, UISchema<unknown>>
  const uiSchemaName = expectationClass[0].toLowerCase() + expectationClass.slice(1)
  return uiSchemaName in uiSchemas ? uiSchemas[uiSchemaName as SupportedExpectation] : undefined
}
