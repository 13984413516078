import { Button, Form, Input, Checkbox, Row, Radio, Flex } from "antd"
import { Image } from "src/ui/Image"
import { useMutation } from "@apollo/client"
import { BodyRegular } from "src/ui/typography/Text"
import { CheckCircleFilled } from "@ant-design/icons"
import { FeedbackMessageDoNotUse } from "src/ui/FeedbackMessage"
import { SET_ORG_NAME_ERROR_TEXT } from "src/Expectation/words"
import { UpdateOrganizationDocument } from "src/api/graphql/graphql-operations"
import { currentOrgIdVar } from "src/organizations"
import { setLocalStorageItem } from "src/common/utils/local-storage"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"
import { useEffect, useState } from "react"
import { useAnalytics } from "src/analytics/useAnalytics"
import { useNavigate } from "react-router-dom"
import {
  geLogoStyle,
  OnboardingSurveyModal,
  HeaderContainer,
  HeaderSecondary,
  WelcomeHeaderText,
} from "src/ui/OnboardingSurvey/styles"
import { Background, ScrollableMainContainer } from "src/ui/OnboardingFlow/styles"

import { DataSourceItems } from "src/ui/OnboardingSurvey/DataSourceUtils"
import { JobTitleItems } from "src/ui/OnboardingSurvey/JobTitleUtils"
import { theme } from "src/ui/themes/theme"
import { TimelineUtils } from "src/ui/OnboardingSurvey/TimelineUtils"
import { RadioGroup } from "src/ui/Radio/RadioGroup.tsx"

export const OnboardingSurvey = () => {
  const analytics = useAnalytics()
  const [submittable, setSubmittable] = useState<boolean>(false)
  const [errorText, setErrorText] = useState("")
  const [form] = Form.useForm()
  const ctx = useCurrentUser()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    analytics?.capture("survey.seen", {
      survey_name: "onboarding_mar2024",
      survey_id: "4da5b5f0-9cfc-4fe1-9041-6a59958c010b", // random uuid generated for this survey
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [updateOrganization, { error }] = useMutation(UpdateOrganizationDocument, {
    onCompleted: () => {
      ctx.getAccessTokenSilently({ cacheMode: "off" }).then((updatedToken) => {
        setLocalStorageItem("auth0Token", updatedToken)
        navigate("/")
        //the first navigate isnt refreshing the page
        navigate(0)
      })
    },
    onError: () => {
      setLoading(false)
      setErrorText(SET_ORG_NAME_ERROR_TEXT)
    },
  })

  const onSubmit = () => {
    if (loading || error) return
    setLoading(true)
    const orgName = form.getFieldValue("orgName")
    const jobTitle = form.getFieldValue("jobTitle")
    const teamSize = form.getFieldValue("teamSize")
    const timeline = form.getFieldValue("timeline")
    const dataSources = form.getFieldValue("datasourceType")
    const otherDataSources = form.getFieldValue("otherDatasourceType")
    const otherJobTitle = form.getFieldValue("otherJobTitle")

    analytics?.capture("survey.submitted", {
      survey_name: "onboarding_mar2024",
      survey_id: "4da5b5f0-9cfc-4fe1-9041-6a59958c010b", // random uuid generated for this survey
      survey_response: {
        org_name: orgName,
        job_title: jobTitle === "Other" ? otherJobTitle : jobTitle,
        team_size: teamSize,
        data_sources: replaceOther(dataSources, otherDataSources),
        timeline,
      },
    })

    const currentOrgId = currentOrgIdVar()

    updateOrganization({
      variables: {
        input: {
          id: currentOrgId,
          name: orgName,
          title: orgName,
        },
      },
    })
  }

  // Watch all values
  const values = Form.useWatch([], form)

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false))
  }, [form, values])

  return (
    <Background>
      <ScrollableMainContainer>
        <OnboardingSurveyModal>
          <HeaderContainer>
            <Image aria-label="Welcome to GX Cloud!" type="geLogoSmall" svgProps={geLogoStyle} style={geLogoStyle} />
            <div>
              <WelcomeHeaderText>Welcome to GX Cloud!</WelcomeHeaderText>
              <HeaderSecondary>Please answer a few questions to help</HeaderSecondary>
              <br />
              <HeaderSecondary>us understand how you&apos;ll use GX Cloud.</HeaderSecondary>
            </div>
          </HeaderContainer>
          <Form
            validateTrigger={["onSubmit", "onFocus", "onChange"]}
            onFinish={onSubmit}
            scrollToFirstError
            style={{ marginTop: theme.spacing.horizontal.m, width: "100%" }}
            form={form}
            name="onboardingSurvey"
            layout="vertical"
          >
            <Form.Item
              label="Organization name:"
              name="orgName"
              rules={[
                { min: 3, message: "Organization name should have at least 3 characters" },
                { required: true, message: "Organization name is required" },
              ]}
            >
              <Input placeholder="Your organization name" autoComplete="off" aria-label="Organization name input" />
            </Form.Item>
            <Form.Item
              required
              label="What is your job title?"
              name="jobTitle"
              rules={[{ required: true, message: "Job title is required" }]}
            >
              <RadioGroup>
                <Row justify="start">
                  <JobTitleItems showOther={form.getFieldValue("jobTitle") === "Other"} />
                </Row>
              </RadioGroup>
            </Form.Item>
            <Form.Item
              required
              name="teamSize"
              style={{ marginTop: theme.spacing.horizontal.m }}
              label="How many people work on your data team across your entire organization?"
              rules={[{ required: true, message: "Team size is required" }]}
            >
              <RadioGroup buttonStyle="solid">
                <Radio.Button value="Just me">Just me</Radio.Button>
                <Radio.Button value="2-10">2-10</Radio.Button>
                <Radio.Button value="11-50">11-50</Radio.Button>
                <Radio.Button value="50+">50+</Radio.Button>
              </RadioGroup>
            </Form.Item>
            <Form.Item
              name="datasourceType"
              required
              style={{ marginTop: theme.spacing.horizontal.m }}
              label="Which data sources is your team interested in using with GX?"
              rules={[{ required: true, message: "Data Source type is required" }]}
            >
              <Checkbox.Group>
                <Row justify="start">
                  <DataSourceItems showOther={!!form.getFieldValue("datasourceType")?.includes("Other")} />
                </Row>
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="timeline"
              required
              style={{ marginTop: theme.spacing.horizontal.m }}
              label="When do you expect to implement a data quality tool in your production environment?"
              rules={[{ required: true, message: "Setup timeline is a required question" }]}
            >
              <RadioGroup>
                <TimelineUtils />
              </RadioGroup>
            </Form.Item>
            <Flex
              style={{ marginBottom: theme.spacing.scale.xs, justifyContent: "center", opacity: !submittable ? 0 : 1 }}
            >
              <CheckCircleFilled
                style={{
                  fontSize: theme.spacing.scale.xs,
                  color: theme.colors.success.gxSuccess,
                  marginRight: theme.spacing.scale.xxs,
                }}
              />
              <BodyRegular>Thanks for your responses!</BodyRegular>
            </Flex>
            <Button
              htmlType="submit"
              size="large"
              style={{ width: "100%", boxShadow: "none" }}
              type="primary"
              loading={loading}
            >
              Continue to GX Cloud
            </Button>

            {errorText && (
              <FeedbackMessageDoNotUse title="Set organization name error" type="error" description={errorText} />
            )}
          </Form>
        </OnboardingSurveyModal>
      </ScrollableMainContainer>
    </Background>
  )
}

const replaceOther = (arr: string[], otherValue: string) => {
  return arr?.map((item) => (item === "Other" ? otherValue : item))
}
