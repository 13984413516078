import { useState } from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { Col, Row, Card, Descriptions } from "antd"

import { graphql } from "src/api/graphql/gql"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { getDataAssetBreadcrumbs } from "src/DataAssets/AssetDetails/DataAssetBreadcrumbs"
import { DataAssetTabs } from "src/DataAssets/AssetDetails/DataAssetTabs"
import { MetricsTable } from "src/DataAssets/AssetDetails/MetricsTable"
import { SimpleExpectationDrawer } from "src/DataAssets/AssetDetails/Expectations/SimpleExpectationDrawer"
import { useAssetSummaryDescriptionItems } from "src/DataAssets/AssetDetails/useAssetSummaryDescriptionItems"
import { CreateExpectationForAssetDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationForAssetDrawer"
import { CreateExpectationDrawerContextProvider } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { MainContent } from "src/layout/MainContent"
import { HeaderTypes } from "src/ui/PageHeader/types"
import { PageHeader } from "src/ui/PageHeader/PageHeader"

export const OverviewTab_DataAssetDocument = graphql(`
  query OverviewTab_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      name
      datasourceV2 {
        ...DataAssetBreadcrumbs_DataSource
      }
      ...AssetSummaryDescriptionItems_DataAsset
    }
  }
`)

export function OverviewTab() {
  const { assetId } = useParams<Record<"assetId", string>>()
  const dataAssetId = decodeURIComponent(assetId ?? "")

  const { data } = useQuery(OverviewTab_DataAssetDocument, {
    variables: { id: dataAssetId },
    skip: !dataAssetId,
  })
  const isEditor = useRequireRole("EDITOR")
  const isSujeEnabled = useIsFeatureEnabled("sujeEnabled")
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const headerContent: HeaderTypes = {
    title: data?.dataAsset?.name ?? "",
    rootPath: "data-assets",
    navigateBackTo: "/data-assets",
    ...(isEditor && {
      rightActions: {
        ctaButton: {
          type: "primary",
          icon: "plus",
          children: "New Expectation",
          disabled: !dataAssetId,
          onClick: () => setIsDrawerOpen(true),
        },
      },
    }),
    customBreadcrumbs: getDataAssetBreadcrumbs({
      dataSource: data?.dataAsset?.datasourceV2,
      tooltipPlacement: "right",
    }),
    footer: <DataAssetTabs currentTab="overview" />,
  }
  const descriptionItems = useAssetSummaryDescriptionItems(data?.dataAsset)

  return (
    <PageHeader headerContent={headerContent}>
      <MainContent>
        {dataAssetId && !isSujeEnabled && (
          <CreateExpectationDrawerContextProvider open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
            <CreateExpectationForAssetDrawer assetId={dataAssetId} assetName={data?.dataAsset?.name} />
          </CreateExpectationDrawerContextProvider>
        )}
        {dataAssetId && isSujeEnabled && (
          <SimpleExpectationDrawer dataAssetId={dataAssetId} open={isDrawerOpen} close={() => setIsDrawerOpen(false)} />
        )}
        <Row gutter={[24, 24]}>
          <Col xs={24} xl={6}>
            <Card title="Data Asset Information">
              <Descriptions column={1} layout="vertical" items={descriptionItems} />
            </Card>
          </Col>
          <Col xs={24} xl={18} className="sentry-mask">
            {dataAssetId && <MetricsTable assetId={dataAssetId} />}
          </Col>
        </Row>
      </MainContent>
    </PageHeader>
  )
}
