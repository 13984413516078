import { RuleEffect } from "@jsonforms/core"
import type { UISchema } from "@great-expectations/jsonforms-antd-renderers"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { baseUISchemaElements } from "src/Expectation/uiForms/schemas/base-ui-schema-elements"

type WindowedUISchema = {
  properties: {
    _condition: { type: "string" }
    _percent_offset: { type: "string" }
    _percent: { type: "number" }
    _range: { type: "number" }
    _strict: { type: "boolean" }
  }
}

export const expectWindowedColumnMinToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMinToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },

    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMinToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnMaxToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMaxToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMaxToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnMeanToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMeanToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMeanToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnMedianToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnMedianToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnMedianToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnSumToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnSumToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnSumToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnStdevToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnStdevToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnStdevToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnUniqueValueCountToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip: jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnUniqueValueCountToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnProportionOfUniqueValuesToBeBetween = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.column.description,
        style: {
          width: "100%",
        },
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/min_value",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.min_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_min",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.strict_min.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/max_value",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.max_value.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/strict_max",
          formItemProps: {
            tooltip:
              jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema.properties.strict_max.description,
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "fixed values",
              },
            },
          },
        },
      ],
    },
    {
      type: "HorizontalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent_offset",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_strict",
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "dynamic range",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnProportionOfUniqueValuesToBeBetween.schema & WindowedUISchema>

export const expectWindowedColumnValuesToBeNull = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeNull.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "% of null values",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToBeNull.schema.properties.mostly.description,
      },
      rule: {
        effect: RuleEffect.SHOW,
        condition: {
          scope: "#/properties/_condition",
          schema: {
            const: "a fixed %",
          },
        },
      },
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
            addonBefore: "+",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "a dynamic %",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "% of null values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "a dynamic %",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToBeNull.schema & WindowedUISchema>

export const expectWindowedColumnValuesToNotBeNull = {
  type: "VerticalLayout",
  elements: [
    {
      type: "Control",
      scope: "#/properties/column",
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotBeNull.schema.properties.column.description,
      },
    },
    {
      type: "Control",
      scope: "#/properties/_condition",
    },
    {
      type: "Control",
      scope: "#/properties/mostly",
      options: {
        addonAfter: "% of non-null values",
      },
      formItemProps: {
        tooltip: jsonSchemas.expectColumnValuesToNotBeNull.schema.properties.mostly.description,
      },
      rule: {
        effect: RuleEffect.SHOW,
        condition: {
          scope: "#/properties/_condition",
          schema: {
            const: "a fixed %",
          },
        },
      },
    },
    {
      type: "VerticalLayout",
      elements: [
        {
          type: "Control",
          scope: "#/properties/_percent",
          options: {
            addonAfter: "%",
            addonBefore: "+",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "a dynamic %",
              },
            },
          },
        },
        {
          type: "Control",
          scope: "#/properties/_range",
          options: {
            addonAfter: "% of non-null values",
          },
          rule: {
            effect: RuleEffect.SHOW,
            condition: {
              scope: "#/properties/_condition",
              schema: {
                const: "a dynamic %",
              },
            },
          },
        },
      ],
    },
    ...baseUISchemaElements,
  ],
} satisfies UISchema<typeof jsonSchemas.expectColumnValuesToNotBeNull.schema & WindowedUISchema>
