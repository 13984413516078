import { message } from "antd"
import { useMutation, useQuery } from "@apollo/client"
import { CreateExpectationDocument, ExpectationSuiteDocument } from "src/api/graphql/graphql-operations"

import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { useAnalytics } from "src/analytics/useAnalytics"
import { getPathForCreatedExpectation } from "src/Expectation/CreateExpectationDrawer/utils"
import {
  DrawerPage,
  emptyExpectation,
  useCreateExpectationDrawerContext,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { CreateExpectationDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawer"
import { ExpectationSuiteSelectorForDrawer } from "src/Expectation/CreateExpectationDrawer/ExpectationSuiteSelectorForDrawer"
import { ExpectationJsonSchema } from "src/Expectation/uiForms/ExpectationConfigForm"
import { useCallback, useEffect } from "react"
import { CREATE_EXPECTATION_SUCCESS } from "src/Expectation/CreateExpectationDrawer/words"

interface CreateExpectationForAssetAndSuiteDrawerProps {
  assetId: string
  expectationSuiteId: string
}

function CreateExpectationForAssetAndSuiteDrawer(props: CreateExpectationForAssetAndSuiteDrawerProps) {
  const {
    onClose,
    setPageNumber,
    setSuiteId,
    setSuiteName,
    selectedExpectation,
    setSelectedExpectation,
    setJsonValue,
    isReRender,
    setIsReRender,
    form,
    config,
    jsonSchema,
    checkpointId,
    setCheckpointId,
    setScheduleIdAsUnpaused,
  } = useCreateExpectationDrawerContext()

  const ossJsonSchema = jsonSchema as ExpectationJsonSchema

  const posthog = useAnalytics()
  const { navigateInOrg } = useOrganizationSlug()

  useEffect(() => {
    setSuiteId(props.expectationSuiteId)
  }, [props.expectationSuiteId, setSuiteId])

  const expectationSuite = useQuery(ExpectationSuiteDocument, {
    variables: { id: props.expectationSuiteId },
    onCompleted: (data) => {
      setSuiteName(data.expectationSuiteV2?.name ?? null)
      setCheckpointId(data.expectationSuiteV2?.validations[0]?.checkpoints[0]?.id)
    },
  })

  // Use this mutation when creating an Expectation for an existing Suite
  const [createExpectationForSuiteMutation, createExpectationForSuiteMutationResult] = useMutation(
    CreateExpectationDocument,
    {
      variables: {
        input: {
          expectationSuiteId: props.expectationSuiteId,
          config: config,
        },
      },
      refetchQueries: [{ query: ExpectationSuiteDocument, variables: { id: props.expectationSuiteId } }],
      onError: () => {
        posthog?.capture("expectation.create_failed", {
          expectationType: selectedExpectation.value,
          expectationTitle: selectedExpectation.title,
          config: config,
        })
      },
    },
  )

  const handlePostCreation = useCallback(async () => {
    message.success(CREATE_EXPECTATION_SUCCESS, MESSAGE_DURATION_SECONDS)
    posthog?.capture("expectation.create_succeeded", {
      expectationType: selectedExpectation.value,
      expectationTitle: selectedExpectation.title,
      config: config,
    })

    // only unpause the schedule if there are no expectations
    // if the Expectation is the first one we are adding, then the existing Suite has a length of 0
    const isFirstExpectation = (expectationSuite.data?.expectationSuiteV2?.expectations?.length ?? 0) === 0
    await setScheduleIdAsUnpaused(checkpointId, isFirstExpectation)

    if (isReRender) {
      setPageNumber(DrawerPage.ExpectationPicker)
      setSelectedExpectation(emptyExpectation)
      setJsonValue("")
      setIsReRender(false)
    } else {
      onClose()
      const reroutePath = getPathForCreatedExpectation(props.assetId, props.expectationSuiteId, config, ossJsonSchema)
      navigateInOrg(reroutePath)
    }
  }, [
    checkpointId,
    config,
    expectationSuite.data?.expectationSuiteV2?.expectations?.length,
    isReRender,
    navigateInOrg,
    onClose,
    ossJsonSchema,
    posthog,
    props.assetId,
    props.expectationSuiteId,
    setIsReRender,
    setJsonValue,
    setPageNumber,
    setScheduleIdAsUnpaused,
    selectedExpectation,
    setSelectedExpectation,
  ])

  const onCloseAndResetMutation = () => {
    if (createExpectationForSuiteMutationResult.error?.message) {
      createExpectationForSuiteMutationResult.reset()
    }
    setPageNumber(DrawerPage.ExpectationPicker)
    onClose()
  }

  const onSave = async (addMore?: "addMore") => {
    try {
      await form.validateFields()
      if (addMore) {
        setIsReRender(true)
      }
      await createExpectationForSuiteMutation()
      handlePostCreation()
    } catch (error) {
      console.error(error)
    }
  }

  const expectationSuiteSelectorForDrawer = (
    <ExpectationSuiteSelectorForDrawer
      initialSuiteName={expectationSuite.data?.expectationSuiteV2?.name ?? ""}
      allowChangeInitialSuiteName={false}
    />
  )

  return (
    <CreateExpectationDrawer
      onClose={onCloseAndResetMutation}
      onSave={onSave}
      expectationSuiteSelectorForDrawer={expectationSuiteSelectorForDrawer}
      loading={createExpectationForSuiteMutationResult.loading}
      error={createExpectationForSuiteMutationResult.error?.message}
      variant="For Asset and Suite"
    />
  )
}

export { CreateExpectationForAssetAndSuiteDrawer }
