import { ExpectationChange } from "src/api/graphql/graphql"
import { Icon, IconTypes } from "src/ui/Icon"
import { Col, Divider as AntdDivider, Row, Space } from "antd"
import styled, { css, useTheme } from "styled-components"
import { getMetaNotesFromExpectationConfiguration, getRenderer } from "src/Expectation/utils"
import { formatLocalDateWithTime } from "src/common/utils/formatTime"
import { MetaNotes as MetaNotesType } from "src/Expectation/utils/parsing"
import { MetaNotes } from "src/Expectation/MetaNotes"

const StyledListContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing.vertical.xs};
    margin-top: ${theme.spacing.vertical.xs};
  `}
`

const StyledDividerCol = styled(Col)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledUserEmail = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

interface ExpectationHistoryListProps {
  changeHistory: ExpectationChange[]
  isExpectationDeleted?: boolean
}

type ChangeActions = "create" | "update" | "delete"

const changeActionIcons: { [key in ChangeActions]: { iconName: IconTypes; title: string } } = {
  create: {
    iconName: "plus",
    title: "Expectation created",
  },
  update: {
    iconName: "edit",
    title: "Expectation updated",
  },
  delete: {
    iconName: "trash",
    title: "Expectation deleted",
  },
}

interface ExpectationChangeHistoryListItemProps {
  change: ExpectationChange
  isExpectationDeleted?: boolean
  metaNotes?: MetaNotesType | null | undefined
}

const ExpectationChangeHistoryListItem = ({
  change,
  isExpectationDeleted,
  metaNotes,
}: ExpectationChangeHistoryListItemProps) => {
  const theme = useTheme()
  const icon =
    change.action in changeActionIcons
      ? changeActionIcons[change.action as ChangeActions]
      : { iconName: "question" as IconTypes, title: "Unknown" }
  return (
    <Row>
      <Col xs={24} md={9} xl={7} style={{ color: theme.colors.neutralColorPalette.blacks.colorTextQuaternary }}>
        <div>{formatLocalDateWithTime(change.createdAt)}</div>
        <StyledUserEmail title={change.userEmail ?? ""}>{change.userEmail}</StyledUserEmail>
      </Col>
      <StyledDividerCol span={1}>
        <Icon
          name={icon.iconName}
          size="14px"
          title={icon.title}
          color={theme.colors.neutralColorPalette.blacks.colorTextQuaternary}
          lineHeight="18px"
        />
        <AntdDivider
          type="vertical"
          style={{ borderColor: theme.colors.neutralColorPalette.blacks.colorTextQuaternary, height: "100%" }}
        />
      </StyledDividerCol>
      <Col xs={23} md={14} xl={16}>
        <Space direction="vertical" style={{ display: "flex" }}>
          {getRenderer({
            renderedValue: change.expectationConfiguration.renderedContent?.[0],
            fallback: change.expectationConfiguration.expectationType ?? "",
            isExpectationDeleted: isExpectationDeleted,
            isDeletionAction: change.action === "delete",
            kwargs: change.expectationConfiguration.kwargs,
          })}
          {metaNotes && <MetaNotes metaNotes={metaNotes} />}
        </Space>
      </Col>
    </Row>
  )
}

const ExpectationChangeHistoryList = ({ changeHistory, isExpectationDeleted }: ExpectationHistoryListProps) => {
  return (
    <StyledListContainer>
      {changeHistory.map((change) => {
        const metaNotes = change.expectationConfiguration.renderedContent
          ? getMetaNotesFromExpectationConfiguration(change.expectationConfiguration)
          : null
        return (
          <ExpectationChangeHistoryListItem
            change={change}
            isExpectationDeleted={isExpectationDeleted}
            key={change.createdAt}
            metaNotes={metaNotes}
          />
        )
      })}
    </StyledListContainer>
  )
}

export { ExpectationChangeHistoryList as ExpectationChangeHistory }
