import { message } from "antd"
import { useMutation, useQuery } from "@apollo/client"
import { graphql } from "src/api/graphql"
import {
  CreateExpectationDocument,
  CreateExpectationSuiteWithExpectationCheckpointAndSplitterDocument,
  CreateExpectationSuiteWithExpectationCheckpointAndSplitterMutation,
  ExpectationSuiteDocument,
} from "src/api/graphql/graphql-operations"
import { ChangeEvent, useCallback, useEffect, useMemo } from "react"

import { useOrganizationSlug } from "src/organizations/useOrganizationSlug"
import { CreateExpectationDrawer } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawer"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { useAnalytics } from "src/analytics/useAnalytics"
import { getPathForCreatedExpectation } from "src/Expectation/CreateExpectationDrawer/utils"
import {
  DrawerPage,
  emptyExpectation,
  useCreateExpectationDrawerContext,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"
import { ExpectationSuiteSelectorForDrawer } from "src/Expectation/CreateExpectationDrawer/ExpectationSuiteSelectorForDrawer"
import { ExpectationJsonSchema } from "src/Expectation/uiForms/ExpectationConfigForm"
import { refetchDataAssetExpectationSuites } from "src/DataAssets/AssetDetails/DataAssetTabs"

import { transformBatchDefinitionData } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { CREATE_EXPECTATION_SUCCESS } from "src/Expectation/CreateExpectationDrawer/words"

const DataAssetNameDocument = graphql(`
  query dataAssetName($id: UUID!) {
    dataAsset(id: $id) {
      name
    }
  }
`)

const ExpectationSuitesIdsAndNamesDocument = graphql(`
  query expectationSuitesIdsAndNames($options: ExpectationSuitesV2ListOptions) {
    expectationSuitesV2(options: $options) {
      id
      name
    }
  }
`)

interface CreateExpectationForAssetDrawerProps {
  assetId: string
  assetName?: string
}

function CreateExpectationForAssetDrawer(props: CreateExpectationForAssetDrawerProps) {
  const {
    onClose,
    setPageNumber,
    suiteId, // suiteId null state represents whether or not an expectation suite exists
    setSuiteId,
    suiteName,
    setSuiteName,
    selectedExpectation,
    setSelectedExpectation,
    setJsonValue,
    setIsReRender,
    form,
    batchDefinitionData,
    config,
    jsonSchema,
    setScheduleIdAsUnpaused,
  } = useCreateExpectationDrawerContext()

  const ossJsonSchema = jsonSchema as ExpectationJsonSchema

  const posthog = useAnalytics()
  const { navigateInOrg } = useOrganizationSlug()

  const splitterConfig = transformBatchDefinitionData(batchDefinitionData)

  const { data: dataAssetQuery } = useQuery(DataAssetNameDocument, {
    variables: {
      id: props.assetId,
    },
  })

  const assetNameForSuite = props.assetName ?? dataAssetQuery?.dataAsset?.name
  useEffect(() => {
    if (suiteName === null && assetNameForSuite) {
      setSuiteName(`${assetNameForSuite} - Default Expectation Suite`)
    }
  }, [suiteName, setSuiteName, assetNameForSuite])

  const expectationSuitesIdsAndNames = useQuery(ExpectationSuitesIdsAndNamesDocument, {
    variables: { options: { filterByAssetRefId: props.assetId } },
    onCompleted: (data) => {
      const existingSuiteName = data?.expectationSuitesV2[0]?.name
      const existingSuiteId = data?.expectationSuitesV2[0]?.id
      if (existingSuiteId !== undefined && existingSuiteName !== undefined) {
        setSuiteName(existingSuiteName)
        setSuiteId(existingSuiteId)
      }
    },
  })

  // get Expectation ID from Suite Name
  const { data: expectationsData, refetch: refetchExpectationSuites } = useQuery(ExpectationSuiteDocument, {
    variables: {
      id: suiteId,
    },
    skip: !suiteId || suiteId.length === 0,
  })

  const checkpointId = expectationsData?.expectationSuiteV2?.validations[0]?.checkpoints[0]?.id

  /**
   * Use this mutation when creating an Expectation for an existing Suite
   */
  const [createExpectationForSuiteMutation, createExpectationForSuiteMutationResult] = useMutation(
    CreateExpectationDocument,
    {
      variables: {
        input: {
          expectationSuiteId: suiteId,
          config: config,
        },
      },
      refetchQueries: [{ query: ExpectationSuiteDocument, variables: { id: suiteId } }],
      onError: () => {
        posthog?.capture("expectation.create_failed", {
          expectationType: selectedExpectation.value,
          expectationTitle: selectedExpectation.title,
          config: config,
        })
      },
    },
  )

  /**
   * The necessary sequence of events after creating an expectation on an existing suite.
   *
   * Check if a schedule exists and enable if it's disabled.
   */
  const handlePostExpectationCreation = useCallback(
    (reRender: boolean) => {
      message.success(CREATE_EXPECTATION_SUCCESS, MESSAGE_DURATION_SECONDS)
      posthog?.capture("expectation.create_succeeded", {
        expectationType: selectedExpectation.value,
        expectationTitle: selectedExpectation.title,
        config: config,
      })

      // only set schedule as unpaused if the we are adding our first Expectation.
      // if the Expectation is the first one we are adding, then the existing Suite has a length of 0
      const isFirstExpectation = (expectationsData?.expectationSuiteV2?.expectations?.length ?? 0) === 0
      setScheduleIdAsUnpaused(checkpointId, isFirstExpectation)

      if (reRender) {
        setPageNumber(DrawerPage.ExpectationPicker)
        setSelectedExpectation(emptyExpectation)
        setJsonValue("")
        setIsReRender(false)
      } else {
        onClose()
        const reroutePath = getPathForCreatedExpectation(props.assetId, suiteId, config, ossJsonSchema)
        navigateInOrg(reroutePath)
      }
    },
    [
      checkpointId,
      config,
      expectationsData?.expectationSuiteV2?.expectations?.length,
      navigateInOrg,
      onClose,
      ossJsonSchema,
      posthog,
      props.assetId,
      setIsReRender,
      setJsonValue,
      setPageNumber,
      setScheduleIdAsUnpaused,
      selectedExpectation,
      setSelectedExpectation,
      suiteId,
    ],
  )

  /**
   *  Use this mutation when creating a new Suite with an Expectation and a Splitter
   */
  const [
    createExpectationSuiteWithExpectationCheckpointAndSplitterMutation,
    createExpectationSuiteWithExpectationCheckpointAndSplitterMutationResult,
  ] = useMutation(CreateExpectationSuiteWithExpectationCheckpointAndSplitterDocument, {
    variables: {
      input: {
        expectationSuiteName: suiteName ?? "",
        config: config,
        dataAssetId: props.assetId,
        splitterConfig,
      },
    },
    onError: () => {
      posthog?.capture("expectation.create_failed", {
        expectationType: selectedExpectation.value,
        expectationTitle: selectedExpectation.title,
        config: config,
      })
    },
  })

  /**
   * The necessary sequence of events after creating a suite on data asset with no expectation suites present.
   *
   * Ensure the incoming suite has both a checkpoint & at least one expectation.
   * Then call setScheduleIdAsUnpaused dependent on those parameters and set the schedule as enabled if
   * expectations are present.
   */
  const handlePostSuiteAndCheckpointCreation = useCallback(
    async (
      reRender: boolean,
      data: CreateExpectationSuiteWithExpectationCheckpointAndSplitterMutation | null | undefined,
    ) => {
      message.success(CREATE_EXPECTATION_SUCCESS, MESSAGE_DURATION_SECONDS)
      posthog?.capture("expectation.create_succeeded", {
        expectationType: selectedExpectation.value,
        expectationTitle: selectedExpectation.title,
        config: config,
      })
      const suiteIdNew = data?.createExpectationSuiteWithExpectationCheckpointAndSplitter?.expectationSuite?.id || ""
      refetchDataAssetExpectationSuites(true)
      const expectationsDataUpdated = await refetchExpectationSuites({
        id: suiteIdNew,
      })
      const checkpointIdUpdated = expectationsDataUpdated?.data?.expectationSuiteV2?.validations[0]?.checkpoints[0]?.id
      // if the Expectation is the first one we are adding, then the current Suite has a length of 1.
      // this is because we are retreiving the Suite after adding the first Expectation.
      const isFirstExpectation = expectationsDataUpdated?.data?.expectationSuiteV2?.expectations?.length === 1
      setScheduleIdAsUnpaused(checkpointIdUpdated, isFirstExpectation)
      setSuiteId(suiteIdNew)

      if (reRender) {
        setPageNumber(DrawerPage.ExpectationPicker)
        setSelectedExpectation(emptyExpectation)
        setJsonValue("")
        setIsReRender(false)
      } else {
        const reroutePath = getPathForCreatedExpectation(
          props.assetId,
          data?.createExpectationSuiteWithExpectationCheckpointAndSplitter?.expectationSuite?.id || "",
          config,
          ossJsonSchema,
        )
        navigateInOrg(reroutePath)
        onClose()
      }
    },
    [
      config,
      navigateInOrg,
      onClose,
      ossJsonSchema,
      posthog,
      props.assetId,
      refetchExpectationSuites,
      setIsReRender,
      setJsonValue,
      setPageNumber,
      setScheduleIdAsUnpaused,
      selectedExpectation,
      setSelectedExpectation,
      setSuiteId,
    ],
  )

  /**
   * Form event watcher
   * @param value
   */
  const handleSuiteNameChange = (value: string) => {
    setSuiteName(value)
    const suiteId = expectationSuitesIdsAndNames.data?.expectationSuitesV2.find((suite) => suite.name === value)?.id
    setSuiteId(suiteId ?? "")
  }

  /**
   * Form event watcher
   * @param e
   */
  const handleSuiteNameInput = (e: ChangeEvent<HTMLInputElement>) => {
    const cleanedName = e.target.value.trim()
    setSuiteName(cleanedName)
    setSuiteId("")
  }

  /**
   * Return polymorphic result type based on whether suiteId was initially present
   */
  const createExpectationMutationResult = useMemo(() => {
    return suiteId
      ? createExpectationForSuiteMutationResult
      : createExpectationSuiteWithExpectationCheckpointAndSplitterMutationResult
  }, [
    createExpectationForSuiteMutationResult,
    createExpectationSuiteWithExpectationCheckpointAndSplitterMutationResult,
    suiteId,
  ])

  /**
   * Ensure the payload for the Suite is always current with state.
   */
  const withSuiteIdMutationPayload = useMemo(() => {
    return {
      variables: {
        input: {
          expectationSuiteId: suiteId,
          config: config,
        },
      },
    }
  }, [config, suiteId])

  /**
   * Ensure the payload for Data Assets is always current with state.
   */
  const withDataAssetMutationPayload = useMemo(() => {
    return {
      variables: {
        input: {
          expectationSuiteName: suiteName ?? "",
          dataAssetId: props.assetId,
          config,
          splitterConfig,
        },
      },
    }
  }, [config, props.assetId, splitterConfig, suiteName])

  /**
   * Handle save and then fork behavior based on whether we have a suiteId.
   *
   * If suiteId is present, then create expectation.
   * If no suiteId present, create a suite, checkpoint, schedule, and expectation.
   *
   * In either case, if expectations are > 0, set the schedule as "enabled"
   */
  const onSave = useCallback(
    async (addMore?: "addMore") => {
      try {
        await form.validateFields()
        const reRender = addMore === "addMore"
        if (suiteId) {
          await createExpectationForSuiteMutation(withSuiteIdMutationPayload)
          handlePostExpectationCreation(reRender)
        } else {
          const res =
            await createExpectationSuiteWithExpectationCheckpointAndSplitterMutation(withDataAssetMutationPayload)
          await handlePostSuiteAndCheckpointCreation(reRender, res.data)
        }
      } catch (error) {
        console.error(error)
      }
    },
    [
      createExpectationForSuiteMutation,
      createExpectationSuiteWithExpectationCheckpointAndSplitterMutation,
      form,
      handlePostExpectationCreation,
      handlePostSuiteAndCheckpointCreation,
      suiteId,
      withDataAssetMutationPayload,
      withSuiteIdMutationPayload,
    ],
  )

  /**
   * Handle drawer close
   */
  const onCloseAndResetMutation = () => {
    if (createExpectationMutationResult.error?.message) {
      createExpectationMutationResult.reset()
    }
    setPageNumber(DrawerPage.ExpectationPicker)
    onClose()
  }

  const expectationSuiteSelectorForDrawer = (
    <ExpectationSuiteSelectorForDrawer
      initialSuiteName={suiteName ?? ""}
      allowChangeInitialSuiteName={true}
      expectationSuites={expectationSuitesIdsAndNames.data?.expectationSuitesV2}
      existingSuiteOnChange={handleSuiteNameChange}
      newSuiteOnChange={handleSuiteNameInput}
    />
  )

  return (
    <CreateExpectationDrawer
      onClose={onCloseAndResetMutation}
      onSave={onSave}
      expectationSuiteSelectorForDrawer={expectationSuiteSelectorForDrawer}
      loading={createExpectationMutationResult.loading}
      error={createExpectationMutationResult.error?.message}
      variant="For Asset"
    />
  )
}

export { CreateExpectationForAssetDrawer }
