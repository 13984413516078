import { useState, useCallback } from "react"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { Form } from "antd"
import { Drawer } from "src/ui/Drawer/Drawer"
import { useMutation, useQuery } from "@apollo/client"
import { LoadingState } from "src/ui/LoadingState"
import { graphql } from "src/api/graphql/gql"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { ExpectationEditorSimplified } from "src/Expectation/ExpectationEditorSimplified"
import { ExpectationPickerSimplified } from "src/Expectation/CreateExpectationDrawer/ExpectationPickerSimplified"
import {
  SaveExpectationFooter,
  SelectExpectationFooter,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerFooters.tsx"
import { SelectedExpectation } from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"

export const ExpectationDrawer_DataAssetDocument = graphql(`
  query expectationDrawer_DataAsset($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      ...ExpectationEditor_DataAssetMetricRun
    }
  }
`)

const AddExpectationToAssetDocument = graphql(`
  mutation addExpectationToAsset($input: AddExpectationToDataAssetInput) {
    addExpectationToDataAsset(input: $input) {
      expectation {
        geCloudId
      }
    }
  }
`)

type Props = {
  open: boolean
  close: () => void
  dataAssetId: string
}
export function SimpleExpectationDrawer({ open, dataAssetId, close }: Props) {
  const [expectationConfiguration, setExpectationConfiguration] = useState({})
  const [selectedExpectation, setSelectedExpectation] = useState<SelectedExpectation>()
  const [drawerPage, setDrawerPage] = useState<DrawerPage>("1 Expectation Picker")
  const [createExpectationMutation, { loading, error: addExpectationToAssetError }] = useMutation(
    AddExpectationToAssetDocument,
    {},
  )
  const [form] = Form.useForm()
  const saveExpectation = useCallback(async () => {
    await form
      .validateFields()
      .catch(() => {
        // nothing to do here; form validation will render error text as feedback
      })
      .then(async () => {
        await createExpectationMutation({
          variables: { input: { dataAssetId, expectationConfig: JSON.stringify(expectationConfiguration) } },
        }) // todo: handle mutation errors here or in useMutation hook options
      })
  }, [createExpectationMutation, dataAssetId, expectationConfiguration, form])

  const { data } = useQuery(ExpectationDrawer_DataAssetDocument, {
    variables: {
      id: dataAssetId,
    },
    skip: !dataAssetId,
  })

  const onSelectExpectation = (expectation: SelectedExpectation) => {
    setSelectedExpectation(expectation)
    setDrawerPage("2 Expectation Editor")
  }

  return (
    <Drawer
      title="New Expectation"
      placement="right"
      size="large"
      footer={
        <Footer
          drawerPage={drawerPage}
          setDrawerPage={setDrawerPage}
          onSave={saveExpectation}
          onSelectExpectation={onSelectExpectation}
          loading={loading}
        />
      }
      destroyOnClose
      open={open}
      onClose={close}
    >
      {drawerPage === "1 Expectation Picker" && (
        <ExpectationPickerSimplified onSelectExpectation={onSelectExpectation} />
      )}
      {drawerPage === "2 Expectation Editor" && selectedExpectation?.title ? (
        <Form form={form}>
          <ExpectationEditorSimplified
            value={expectationConfiguration}
            title={selectedExpectation.title}
            expectationCategory={selectedExpectation.category}
            onChange={(newJson) => {
              setExpectationConfiguration(newJson)
            }}
            expectationType={selectedExpectation.value}
            dataAsset={data?.dataAsset ?? undefined}
          />
          {addExpectationToAssetError && (
            <AlertBanner message="Failed to add Expectation" description={addExpectationToAssetError} />
          )}
        </Form>
      ) : (
        <LoadingState loading={loading} />
      )}
    </Drawer>
  )
}

type DrawerPage = "1 Expectation Picker" | "2 Expectation Editor"
type FooterProps = {
  drawerPage: DrawerPage
  setDrawerPage: (page: DrawerPage) => void
  onSave: (addMore?: "addMore") => void
  loading: boolean
  onSelectExpectation: (expectation: SelectedExpectation) => void
}
function Footer({ drawerPage, setDrawerPage, onSave, loading, onSelectExpectation }: FooterProps) {
  const customSqlExpectationsEnabled = useIsFeatureEnabled("customSqlExpectationsEnabled")
  if (customSqlExpectationsEnabled && drawerPage === "1 Expectation Picker") {
    return <SelectExpectationFooter onSelectExpectation={onSelectExpectation} />
  }
  if (drawerPage === "2 Expectation Editor") {
    return (
      <SaveExpectationFooter
        onBack={() => setDrawerPage("1 Expectation Picker")}
        onSave={onSave}
        onSaveAndAdd={() => {
          onSave("addMore")
        }}
        saveLoading={loading}
      />
    )
  }
  return null
}
