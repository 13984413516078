import { Row, Radio } from "antd"
import { StyledCol } from "src/ui/OnboardingSurvey/styles"

const timeline = [
  { value: "Immediately", label: "Immediately" },
  {
    value: "Within the next quarter",
    label: "Within the next quarter",
  },
  { value: "Later", label: "Later" },
  { value: "Just browsing", label: "Just browsing" },
]

export const TimelineUtils = () => {
  return (
    <Row justify="start">
      {timeline.map(({ value, label }) => (
        <StyledCol key={value} span={24}>
          <Radio value={value}>{label}</Radio>
        </StyledCol>
      ))}
    </Row>
  )
}
