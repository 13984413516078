import React, { useEffect, useMemo } from "react"
import { ApolloProvider } from "@apollo/client"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { setContext } from "@apollo/client/link/context"
import { generateApolloClientWithAuth0 } from "src/api/apolloClient"
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "src/common/utils/local-storage"
import { LoadingState } from "src/ui/LoadingState"
import { useOrganizations } from "src/organizations/useOrganizations"
import { Sentry } from "src/observability/sentry/sentry"
import { useAnalytics } from "src/analytics/useAnalytics"
import { useSetFeatureFlagUserAndOrgContext } from "src/common/hooks/useSetFeatureFlagUserAndOrgContext"
import { useCurrentUser } from "src/common/hooks/useCurrentUser"

interface ApolloAuthenticatedProvider {
  children: React.ReactNode
}

type noAuthorizedUser = "No authorized user found. Please login."
type noSessionFound = "No session was found."
type userNotLoggedIn = "User is not logged in."
type tokenNotActive = "Error on refresh token: Token is not active."
type refreshTokenMissing = "refresh_token is missing!"

export type AuthErrorStatus = noAuthorizedUser | noSessionFound | userNotLoggedIn | tokenNotActive | refreshTokenMissing

function ApolloAuth0AuthenticatedProvider({ children }: ApolloAuthenticatedProvider) {
  const posthog = useAnalytics()
  const { error, getAccessTokenSilently, logout, isLoading: authLoading, isAuthenticated, user } = useCurrentUser()

  const { currentOrg, isLoading: orgLoading } = useOrganizations()
  const isLoading = authLoading || orgLoading

  const organizationId = currentOrg?.id ?? ""
  const dataPlaneUrl = currentOrg?.dataPlaneUrl ?? ""

  const getIsTokenExpiredOrInvalid = (id: string): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const decodedToken = JSON.parse(atob(id.split(".")[1]))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { exp } = decodedToken
    const expTime = new Date(exp * 1000)
    const expTimeMiliseconds = expTime.getTime()
    const expTimeIsValid = !isNaN(expTimeMiliseconds)

    if (expTime instanceof Date && expTimeIsValid) {
      return expTime < new Date()
    }
    return true
  }

  const authLink = setContext(async () => {
    let token = getLocalStorageItem("auth0Token")

    try {
      if (!token || getIsTokenExpiredOrInvalid(token)) {
        token = await getAccessTokenSilently()
        setLocalStorageItem("auth0Token", token)
      }

      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    } catch (e: unknown) {
      console.error(e)
      removeLocalStorageItem("auth0Token")
      logout({ logoutParams: { returnTo: `${window.location.origin}/login` } })
    }
  })

  // setting org context for Launch Darkly
  useSetFeatureFlagUserAndOrgContext()

  /*
   * Initialize Sentry Replay excluding the synthetic user
  https://docs.sentry.io/platforms/javascript/session-replay/understanding-sessions/#manually-starting-replay
   */
  const replay = Sentry.getReplay()
  useEffect(() => {
    const initializeSentryReplays = async () => {
      try {
        replay?.start()
      } catch (e) {
        console.error(e)
      }
    }
    const usersExcludedFromReplays = ["synthetic@greatexpectations.io", "synthetic+runner@greatexpectations.io"]
    if (user && !usersExcludedFromReplays.includes(user?.email)) {
      initializeSentryReplays()
    }
  }, [replay, user])

  useEffect(() => {
    // check that loading finished and posthog exists before calling identify on it
    if (user && currentOrg && posthog) {
      Sentry.setUser({ auth0_sub: user.sub, id: user.id, email: user.email, org_id: organizationId })
      Sentry.setTag("org_id", organizationId) // set this explicitly since the user.org_id tag is not searchable in Sentry
      posthog.identify(user.id, { auth0_sub: user.sub, email: user.email, org_id: organizationId })
      posthog.group("organization", organizationId, { name: currentOrg.name })
    }
  }, [user, organizationId, currentOrg, posthog])

  const client = useMemo(() => {
    if (isAuthenticated && organizationId && dataPlaneUrl) {
      return generateApolloClientWithAuth0(authLink, dataPlaneUrl, isAuthenticated, organizationId, logout)
    }

    return null
    // TODO: add authLink and handleAuthErrorMessages to the deps list
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, isAuthenticated, dataPlaneUrl])

  if (isLoading) {
    return <LoadingState loading />
  }

  if (error) {
    removeLocalStorageItem("auth0Token")
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  return client === null ? null : <ApolloProvider client={client}>{children}</ApolloProvider>
}

const ApolloAuth0AuthenticatedProviderWithAuthenticationRequired = withAuthenticationRequired(
  ApolloAuth0AuthenticatedProvider,
  {
    returnTo: window.location.pathname,
    // eslint-disable-next-line react/display-name
    onRedirecting: () => <LoadingState loading />,
  },
)

export { ApolloAuth0AuthenticatedProviderWithAuthenticationRequired as ApolloAuth0AuthenticatedProvider }
