/* eslint-disable react-refresh/only-export-components */ // FIXME
import { message, Space, Alert } from "antd"
import { ExpectationEditor } from "src/Expectation/ExpectationEditor"
import { useMutation, useQuery } from "@apollo/client"
import { useState } from "react"
import {
  EDITOR_DRAWER_TITLE,
  EDITOR_SUCCESS_MESSAGE,
  EXPECTATION_ERROR_TEXT,
  UPDATE_EXPECTATION_ERROR,
} from "src/Expectation/words"
import { ExpectationRenderer } from "src/Expectation/ExpectationRenderer"
import { UpdateExpectationDocument } from "src/api/graphql/graphql-operations"
import { Drawer } from "src/ui/Drawer/Drawer"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { MESSAGE_DURATION_SECONDS } from "src/common/config"
import { camelCase, get, merge } from "lodash-es"
import { jsonSchemas } from "src/schemas/expectation-catalog-schemas"
import { useParams } from "react-router-dom"
import { graphql } from "src/api/graphql"

type JSONString = string

interface EditExpectationDrawerProps {
  expectationSuiteId: string
  expectationId: string
  expectationType: string
  expectationJson: JSONString
  renderer: ExpectationRenderer
  isVisible: boolean
  onClose: () => void
}

interface FooterProps {
  onSave: () => void
  saveDisabled: boolean
}

function EditExpectationFooter({ onSave, saveDisabled }: FooterProps) {
  return (
    <Drawer.Footer>
      <Drawer.FooterButton type="primary" onClick={onSave} disabled={saveDisabled}>
        Save
      </Drawer.FooterButton>
    </Drawer.Footer>
  )
}

function formatValue(value: string, mode: "yaml" | "python" | "json"): string {
  try {
    if (mode === "json") {
      return JSON.stringify(JSON.parse(value), null, 4)
    }
  } catch {
    return value
  }
  return value
}

export function getErrorType(value: string): keyof typeof EXPECTATION_ERROR_TEXT {
  if (!value) {
    return "EMPTY"
  }

  let obj = {}
  try {
    obj = JSON.parse(value)
  } catch {
    return "INVALID_JSON"
  }

  if (!("kwargs" in obj)) {
    return "MISSING_KWARGS"
  }

  for (const key of Object.keys(obj)) {
    if (!["kwargs", "meta"].includes(key)) {
      return "UNEXPECTED_FIELD"
    }
  }

  return "UNEXPECTED_ERROR"
}

function getInitialValue(json: string): string {
  let parsed = JSON.parse(json)
  if (parsed.kwargs) {
    parsed = merge(parsed, parsed.kwargs)
    delete parsed.kwargs
  }
  if (parsed.meta) {
    parsed = merge(parsed, { notes: parsed.meta.notes })
    delete parsed.meta
  }
  return formatValue(JSON.stringify(parsed), "json")
}

const DataAssetWithLatestMetricsRunDocument = graphql(`
  query dataAssetWithLatestMetricsRun($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      latestMetricRun {
        dataAssetId
        metrics {
          columnDataType
          columnName
          mean
          median
          nullCount
          valueRangeMax
          valueRangeMin
          valueRangeMaxUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
          valueRangeMinUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
        }
        rowCount
      }
    }
  }
`)

export function EditExpectationDrawer({
  expectationSuiteId,
  expectationId,
  expectationType,
  expectationJson,
  renderer,
  isVisible,
  onClose,
}: EditExpectationDrawerProps) {
  const [value, setValue] = useState(getInitialValue(expectationJson))
  const [errorText, setErrorText] = useState<string | undefined>("")
  const expInfo = get(jsonSchemas, `${camelCase(expectationType)}.schema`)
  const title: string = get(expInfo, "title", "")
  const category: string = get(expInfo, "properties.metadata.properties.data_quality_issues.const.0", "")

  const { assetId = "" } = useParams<{
    assetId: string
  }>()
  const { data: dataAssetQueryResult } = useQuery(DataAssetWithLatestMetricsRunDocument, {
    variables: {
      id: assetId,
    },
    skip: !assetId,
  })

  const [updateExpectation, updateExpectationMutation] = useMutation(UpdateExpectationDocument, {
    variables: {
      input: {
        config: value,
        expectationSuiteId,
        id: expectationId,
      },
    },
    refetchQueries: ["expectationSuite"],
    onCompleted: () => {
      message.success(EDITOR_SUCCESS_MESSAGE, MESSAGE_DURATION_SECONDS)
      onClose()
    },
  })
  const handleCancel = () => {
    if (updateExpectationMutation.error?.message) {
      updateExpectationMutation.reset()
    }
    onClose()

    // AntD@4.22.3 destroyOnClose seems broken
    setErrorText("")
    setValue(getInitialValue(expectationJson))
  }

  const handleSave = () => {
    updateExpectation()
  }

  return (
    <Drawer
      title={EDITOR_DRAWER_TITLE}
      onClose={handleCancel}
      open={isVisible}
      onClick={(e) => e.stopPropagation()}
      footer={<EditExpectationFooter onSave={handleSave} saveDisabled={!!errorText} />}
    >
      <Space direction="vertical" size="large">
        <div>
          <ExpectationEditor
            value={value}
            onChange={(newVal, error?) => {
              setErrorText(error)
              setValue(newVal)
            }}
            expectationType={expectationType}
            title={title}
            expectationCategory={category}
            hideExpectationType={true}
            dataAssetWithLatestMetricRun={dataAssetQueryResult?.dataAsset}
            renderer={renderer}
          />
        </div>
        {errorText && (
          <div>
            <Alert type="error" message={errorText} />
          </div>
        )}
        {updateExpectationMutation.error && (
          <AlertBanner message={UPDATE_EXPECTATION_ERROR} description={updateExpectationMutation.error?.message} />
        )}
      </Space>
    </Drawer>
  )
}
