import { useQuery } from "@apollo/client"
import { Alert, Form } from "antd"
import { useEffect, useState, useMemo } from "react"
import {
  DrawerPage,
  SelectedExpectation,
  emptyExpectation,
  useCreateExpectationDrawerContext,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerContext"

import {
  SaveExpectationFooter,
  SelectExpectationFooter,
} from "src/Expectation/CreateExpectationDrawer/CreateExpectationDrawerFooters.tsx"
import {
  CREATE_EXPECTATION_DRAWER_TITLE,
  CREATE_EXPECTATION_ERROR,
} from "src/Expectation/CreateExpectationDrawer/words"
import { Drawer } from "src/ui/Drawer/Drawer"
import { ExpectationEditor } from "src/Expectation/ExpectationEditor"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { LoadingState } from "src/ui/LoadingState"
import { ExpectationPicker } from "src/Expectation/CreateExpectationDrawer/ExpectationPicker"
import { BatchDefinitionForm } from "src/Expectation/CreateExpectationDrawer/BatchDefinitionForm"
import { useIsFeatureEnabled } from "src/common/hooks/useIsFeatureEnabled"
import { theme } from "src/ui/themes/theme"
import { useGetSplitterData } from "src/DataAssets/AssetDetails/Splitters/useGetSplitterData"
import { getIsDateTimeColumn } from "src/DataAssets/AssetDetails/Splitters/splitterUtils"
import { BatchDefinitionDescription } from "src/DataAssets/AssetDetails/Splitters/BatchDefinitionDescription"
import { useParams } from "react-router-dom"
import { graphql } from "src/api/graphql"
import { NODE_ENV } from "src/common/env"
import { CreateExpectationFeedbackButton } from "src/Expectation/CreateExpectationDrawer/CreateExpectationFeedback"
interface CreateExpectationDrawerProps {
  onClose: () => void
  onSave: (addMore?: "addMore") => Promise<void>
  expectationSuiteSelectorForDrawer: JSX.Element
  loading: boolean
  error?: string
  variant: string
}

const DataAssetWithLatestMetricsRunDocument = graphql(`
  query dataAssetWithLatestMetricsRun($id: UUID!) {
    dataAsset(id: $id) {
      id
      __typename
      latestMetricRun {
        dataAssetId
        metrics {
          columnDataType
          columnName
          mean
          median
          nullCount
          valueRangeMax
          valueRangeMin
          valueRangeMaxUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
          valueRangeMinUnion {
            __typename
            ...MetricValueStringTypeFragment
            ...MetricValueFloatTypeFragment
          }
        }
        rowCount
      }
    }
  }
`)

function CreateExpectationDrawer({
  onClose,
  onSave,
  expectationSuiteSelectorForDrawer,
  loading,
  error,
  variant,
}: CreateExpectationDrawerProps) {
  const {
    open,
    pageNumber,
    setPageNumber,
    suiteName,
    selectedExpectation,
    setSelectedExpectation,
    jsonValue,
    setJsonValue,
    form,
    batchDefinitionForm,
    batchDefinitionData,
    setBatchDefinitionData,
    suiteId,
  } = useCreateExpectationDrawerContext()

  const [expectationEditorError, setExpectationEditorError] = useState<string | undefined>("")
  const { assetId: _assetId = "" } = useParams<{
    assetId: string
  }>()
  const dataAssetId = decodeURIComponent(_assetId)

  const { data: dataAssetQueryResult } = useQuery(DataAssetWithLatestMetricsRunDocument, {
    variables: {
      id: dataAssetId,
    },
    skip: !dataAssetId,
  })

  useEffect(() => {
    const resetFormValues = () => {
      setPageNumber(DrawerPage.ExpectationPicker)
      setSelectedExpectation(emptyExpectation)
      setJsonValue("")
    }

    if (!open) {
      resetFormValues()
    }
  }, [open, jsonValue, setJsonValue, setPageNumber, setSelectedExpectation])

  const handleExpectationSelect = (clickedExpectation: SelectedExpectation) => {
    if (clickedExpectation.value !== selectedExpectation.value) {
      // Reset the form when a new expectation is selected, preserve state if the same expectation is clicked again
      setSelectedExpectation(clickedExpectation)
      setJsonValue("")
    }
    if (clickedExpectation.title && suiteName) {
      pageNumber !== DrawerPage.ExpectationEditor && setPageNumber(DrawerPage.ExpectationEditor)
    }
  }

  const handleJsonValueChange = (value: string) => {
    setJsonValue(value)
  }

  const { splitter, isSplitterSupported } = useGetSplitterData({ isVisible: true })
  const batchDefinitionEnabled = useIsFeatureEnabled("batchDefinitionEnabled")
  const customSqlExpectationsEnabled = useIsFeatureEnabled("customSqlExpectationsEnabled")

  const isDateTimeColumn = getIsDateTimeColumn(dataAssetQueryResult?.dataAsset?.latestMetricRun?.metrics)

  const showBatchDefinition = batchDefinitionEnabled && isDateTimeColumn && !suiteId

  const drawerTitle = useMemo(() => {
    if (NODE_ENV === "development") {
      return (
        <>
          {CREATE_EXPECTATION_DRAWER_TITLE}: <span style={{ color: theme.colors.error.gxError }}>{variant}</span>
        </>
      )
    }
    return CREATE_EXPECTATION_DRAWER_TITLE
  }, [variant])

  const getFooterForCurrentPage = () => {
    if (customSqlExpectationsEnabled && pageNumber === DrawerPage.ExpectationPicker) {
      return <SelectExpectationFooter onSelectExpectation={handleExpectationSelect} />
    }
    if (pageNumber === DrawerPage.ExpectationEditor) {
      return (
        <SaveExpectationFooter
          onBack={() => setPageNumber(DrawerPage.ExpectationPicker)}
          onSave={onSave}
          onSaveAndAdd={() => {
            onSave("addMore")
          }}
          saveLoading={loading}
        />
      )
    }
  }

  return (
    <Drawer
      title={drawerTitle}
      placement="right"
      size="large"
      footer={getFooterForCurrentPage()}
      destroyOnClose
      open={open}
      onClose={onClose}
      extra={<CreateExpectationFeedbackButton />}
    >
      <Form form={form} name="createExpectation" preserve={true} layout="horizontal">
        <>
          {pageNumber === DrawerPage.ExpectationPicker && expectationSuiteSelectorForDrawer}
          <ExpectationPicker onSelectExpectation={handleExpectationSelect} />
        </>
        {pageNumber === DrawerPage.ExpectationEditor && (
          <>
            {selectedExpectation.title ? (
              <>
                <>
                  {showBatchDefinition &&
                    (splitter ? (
                      <BatchDefinitionDescription
                        isSplitterSupported={isSplitterSupported}
                        style={{ paddingBottom: theme.spacing.vertical.l }}
                      />
                    ) : (
                      <>
                        <BatchDefinitionForm
                          batchDefinitionForm={batchDefinitionForm}
                          batchDefinitionData={batchDefinitionData}
                          setBatchDefinitionData={setBatchDefinitionData}
                        />
                      </>
                    ))}
                </>
                <ExpectationEditor
                  value={jsonValue}
                  title={selectedExpectation.title}
                  expectationCategory={selectedExpectation.category}
                  onChange={(newJson, error) => {
                    setExpectationEditorError(error)
                    handleJsonValueChange(newJson)
                  }}
                  expectationType={selectedExpectation.value}
                  codeSnippetEditorProps={{ width: "90%" }}
                  dataAssetWithLatestMetricRun={dataAssetQueryResult?.dataAsset}
                />
                {expectationEditorError && (
                  <div>
                    <Alert type="error" message={expectationEditorError} />
                  </div>
                )}
                {error && <AlertBanner message={CREATE_EXPECTATION_ERROR} description={error} />}
              </>
            ) : (
              <LoadingState loading={loading} />
            )}
          </>
        )}
      </Form>
    </Drawer>
  )
}

export { CreateExpectationDrawer }
