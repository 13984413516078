/* eslint-disable react-refresh/only-export-components */ // FIXME
import { FragC } from "src/types/fragment"
import styled, { css } from "styled-components"
import { LabelRegular } from "src/ui/typography/Text"
import {
  ExpectationContainer,
  getMetaNotes,
  getRenderer,
  ValidationResultCaptionRegular,
  ValueTypes,
  parseColumnName,
  SpaceBetweenContainer,
} from "src/Expectation/utils"
import { MetaNotes } from "src/Expectation/MetaNotes"
import { ValidationHistoryTable } from "src/Expectation/ValidationHistoryTable"
import {
  Expectation_ValidationResultFragment,
  Expectation_ValidationResultFragmentDoc,
  RenderedExpectationFragment,
} from "src/api/graphql/graphql-operations"
import { get, uniq } from "lodash-es"
import { SuccessMeter } from "src/Expectation/SuccessMeter"
import { UnexpectedValuesTable } from "src/Expectation/UnexpectedValuesTable"
import { Param } from "src/ui/Param/Param"
const OuterContainer = styled.section<{
  $flexColumn?: boolean
}>`
  ${({ $flexColumn }) =>
    $flexColumn &&
    css`
      flex-direction: column;
    `}
  display: flex;
  justify-content: flex-start;
`

type ValidationResultsProps = {
  validationResults: Expectation_ValidationResultFragment
}

const ValidationResults: FragC<ValidationResultsProps> = ({ validationResults }) => {
  const {
    unexpectedPercent,
    elementCount,
    successCount,
    observedValue,
    partialUnexpectedList,
    unexpectedList,
    unexpectedIndexQuery,
    validationHistoryTable,
  } = getValidationResultsProperties(validationResults)
  const fallback = validationResults.expectationConfig?.expectationType ?? ""
  const success = validationResults?.success ?? null
  const expectationConfig = validationResults?.expectationConfig
  const expectationName = expectationConfig?.expectationType ?? ""
  const renderedContent = validationResults?.expectationConfig?.renderedContent?.[0]
  const metaNotes = renderedContent ? getMetaNotes(renderedContent) : null
  const columnName = parseColumnName(expectationConfig)
  const showSuccessMeter = unexpectedPercent !== undefined && success !== null
  const showUnexpectedValuesTable = Boolean(validationResults.result) && Boolean(unexpectedList) && columnName
  const showObservedValue = observedValue && !showSuccessMeter
  const showValidationResultElement = !showUnexpectedValuesTable && (partialUnexpectedList || unexpectedList)
  const validationResultTitle = partialUnexpectedList ? "SAMPLE UNEXPECTED VALUES: " : "UNEXPECTED VALUES: "
  const validationResultValue = partialUnexpectedList ? uniq<number | string>(partialUnexpectedList) : unexpectedList

  return (
    <ExpectationContainer success={success}>
      <SpaceBetweenContainer>
        {getRenderer({
          renderedValue: expectationConfig?.renderedContent?.[0],
          fallback: expectationName,
          kwargs: expectationConfig?.kwargs,
        })}
      </SpaceBetweenContainer>
      {showSuccessMeter && (
        <SuccessMeter
          observedPercent={100 - unexpectedPercent}
          success={success}
          elementCount={elementCount}
          successCount={successCount}
        />
      )}
      {showObservedValue && (
        <ValidationResultElement title="OBSERVED VALUE: " value={observedValue} fallback={fallback} />
      )}
      {validationHistoryTable && (
        <ValidationHistoryTable
          value={validationHistoryTable}
          ariaLabel={`Validation History for ${
            validationResults.expectationConfig?.expectationType ?? "Current expectation"
          }`}
        />
      )}
      {showUnexpectedValuesTable && (
        <UnexpectedValuesTable
          columnName={columnName}
          result={validationResults.result}
          unexpectedIndexQuery={unexpectedIndexQuery}
        />
      )}
      {showValidationResultElement && (
        <ValidationResultElement fallback={fallback} title={validationResultTitle} value={validationResultValue} />
      )}
      {metaNotes && <MetaNotes metaNotes={metaNotes} />}
    </ExpectationContainer>
  )
}

ValidationResults.fragments = {
  validationResults: Expectation_ValidationResultFragmentDoc,
}

export type ValidationResultElementProps = {
  value:
    | RenderedExpectationFragment
    | undefined
    | ResultTypes["partialUnexpectedList"]
    | ResultTypes["unexpectedList"]
    | ResultTypes["unexpectedCount"]
    | ResultTypes["valueCounts"]
  title: string
  fallback?: string
}

function ValidationResultElement({ value, title, fallback }: ValidationResultElementProps) {
  const isTable =
    typeof value !== "string" &&
    typeof value !== "number" &&
    !Array.isArray(value) &&
    value?.valueType === ValueTypes.atomicTableContent

  const valueIsALiteralValue = typeof value === "string" || typeof value === "number" || Array.isArray(value)

  return (
    <OuterContainer $flexColumn={isTable}>
      <ValidationResultCaptionRegular>{title}</ValidationResultCaptionRegular>
      {valueIsALiteralValue ? (
        <LabelRegular>
          {Array.isArray(value)
            ? value.map((v, i) => <Param key={`${i}${v}`}>{JSON.stringify(v)}</Param>)
            : JSON.stringify(value)}
        </LabelRegular>
      ) : (
        getRenderer({
          fallback,
          renderedValue: value as RenderedExpectationFragment,
          isValidationResult: true,
        })
      )}
    </OuterContainer>
  )
}

export { ValidationResults }

interface ResultTypes {
  unexpectedPercent?: number
  elementCount?: number
  successCount?: number
  unexpectedCount?: number
  partialUnexpectedList?: (string | number)[]
  unexpectedList?: (string | number)[]
  valueCounts?: Record<string, string | number>[]
  observedValue?: RenderedExpectationFragment
  unexpectedIndexQuery?: string
  validationHistoryTable?: TableValueType
}

export type TableValueType = {
  // Jose 02/27: This type is defined in the GraphQL schema, we may need to refine the typing to reumove the use of any
  __typename: "TableType"
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headerRow?: any[] | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table?: (any[] | null | undefined)[] | null
}

function getValidationResultsProperties(validationResult: Expectation_ValidationResultFragment): ResultTypes {
  if (typeof validationResult.result !== "string") {
    throw new Error(
      "type error: unable to parse validationResult.result. expected string, got " + typeof validationResult.result,
    )
  }
  const [unexpectedPercent, elementCount, unexpectedCount] = parseNumbers(validationResult.result, [
    "unexpected_percent",
    "element_count",
    "unexpected_count",
  ])
  const successCount =
    typeof elementCount === "number" && typeof unexpectedCount === "number" ? elementCount - unexpectedCount : undefined
  const result = JSON.parse(validationResult.result)
  const observedValue = parseObservedValue(validationResult)
  const partialUnexpectedList =
    result.partial_unexpected_list && result.partial_unexpected_list.length > 0
      ? result.partial_unexpected_list
      : undefined
  const unexpectedList =
    result.unexpected_list && result.unexpected_list.length > 0 ? result.unexpected_list : undefined
  const unexpectedIndexQuery = parseUnexpectedIndexQuery(validationResult.result)
  const validationHistoryTable =
    validationResult.validationHistoryTable?.value?.__typename === "TableType"
      ? validationResult.validationHistoryTable.value
      : undefined
  return {
    unexpectedPercent,
    elementCount,
    successCount,
    observedValue,
    partialUnexpectedList,
    unexpectedList,
    unexpectedCount,
    unexpectedIndexQuery,
    validationHistoryTable,
  }
}

function parseObservedValue(validationResult?: Expectation_ValidationResultFragment) {
  // hack to prevent us from trying to render graph and table types
  if (
    [ValueTypes.atomicTableContent, ValueTypes.atomicGraphContent].includes(
      validationResult?.renderedContent?.[0]?.value?.__typename as ValueTypes,
    )
  ) {
    return undefined
  }
  return validationResult?.renderedContent?.[0]?.name === "atomic.diagnostic.observed_value"
    ? validationResult.renderedContent[0]
    : undefined
}

// parseNumbers returns the numeric values specified in the result format by the keys arguments.
// The output array size will equal the keys array size. If any of the values aren't numeric it returns
// undefined for that key.
export function parseNumbers(resultJSON: string | null | undefined, keys: Array<string>): Array<number | undefined> {
  try {
    const result = JSON.parse(resultJSON as string)
    return keys.map((key) => {
      try {
        const raw_value = get(result, key, undefined)
        // Number(null) is 0
        const val = raw_value === null ? undefined : Number(raw_value)
        if (Number.isNaN(val)) {
          throw new Error("not a number")
        }
        return val
      } catch {
        return undefined
      }
    })
  } catch {
    return keys.map(() => undefined)
  }
}

// parseUnexpectedIndexQuery returns the unexpected index query for map Expectations validating with
// the COMPLETE result format and unexpected index column names are provided
// if not found, return undefined
export function parseUnexpectedIndexQuery(resultJSON: string | null | undefined): string | undefined {
  try {
    const val = get(JSON.parse(resultJSON as string), "unexpected_index_query", undefined)
    if (Array.isArray(val)) {
      return "[" + val.join(", ") + "]"
    }
    return val
  } catch {
    return undefined
  }
}
