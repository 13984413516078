import { Space, message } from "antd"
import { AppModal } from "src/ui/AppModal/AppModal"
import { SubtitleSemiBold } from "src/ui/typography/Text"
import { Icon } from "src/ui/Icon/Icon"
import { useTheme } from "styled-components"
import { AlertBanner } from "src/ui/Alert/AlertBanner"
import { useMutation } from "@apollo/client"
import { useState } from "react"
import { QUICK_MESSAGE_DURATION } from "src/common/config"
import { ALERT_DELETE_SUCCESS_MESSAGE } from "src/Alerts/words"
import { graphql } from "src/api/graphql/gql"

type DeleteSlackActionModalProps = {
  open: boolean
  checkpointId: string
  onClose: () => void
  slackActionName: string
  refetch: () => void
}

export const DeleteSlackActionDocument = graphql(`
  mutation DeleteSlackNotificationAction($input: DeleteSlackActionInput!) {
    deleteSlackAction(input: $input) {
      checkpoint {
        id
        name
        actionList
      }
    }
  }
`)

export const DeleteSlackActionModal = ({
  open,
  checkpointId,
  onClose,
  slackActionName,
  refetch,
}: DeleteSlackActionModalProps) => {
  const [error, setError] = useState<string>("")
  const [deleteSlackAction, { loading: deleteSlackActionLoading }] = useMutation(DeleteSlackActionDocument, {
    onCompleted: () => {
      message.success(ALERT_DELETE_SUCCESS_MESSAGE, QUICK_MESSAGE_DURATION)
      refetch()
      onClose()
    },
    onError: (error) => {
      setError(error.message)
    },
  })
  const theme = useTheme()
  return (
    <AppModal
      open={open}
      width={550}
      onCancel={onClose}
      onOk={() => {
        deleteSlackAction({
          variables: {
            input: {
              checkpointId,
              name: slackActionName,
            },
          },
        })
      }}
      confirmLoading={deleteSlackActionLoading}
      okButtonProps={{ danger: true }}
      title={
        <Space direction="horizontal" align="center">
          <Icon size="28px" name="trash" lineHeight="2px" color={theme.colors.error.gxError} />
          <SubtitleSemiBold>Delete alert</SubtitleSemiBold>
        </Space>
      }
    >
      <>
        This alert will be permanently deleted and cannot be restored. Are you sure you want to delete{" "}
        <b>{slackActionName}</b>?
      </>
      {error && <AlertBanner message="Error deleting alert" description={error} />}
    </AppModal>
  )
}
