import { UilSort } from "@iconscout/react-unicons"
import { Select, Space } from "antd"
import { getAllDataQualityIssues } from "src/Expectation/CreateExpectationDrawer/utils"
import { EXPECTATION_PICKER_FILTER_PLACEHOLDER } from "src/Expectation/CreateExpectationDrawer/words"

type FilterExpectationsProps = {
  value?: string
  onSelect: (value: string) => void
  categories?: { value: string; label: string }[]
}

export const FilterExpectations = ({ value, onSelect, categories }: FilterExpectationsProps) => {
  const options = categories ?? getAllDataQualityIssues()

  return (
    <Space align="center" size="small">
      <span>Filter by:</span>
      <Select
        allowClear
        value={value}
        suffixIcon={<UilSort style={{ width: 16 }} />}
        style={{ minWidth: 210 }}
        placeholder={EXPECTATION_PICKER_FILTER_PLACEHOLDER}
        options={options}
        onChange={onSelect}
      />
    </Space>
  )
}
