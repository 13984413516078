import { withJsonFormsControlProps } from "@jsonforms/react"
import { ControlProps, JsonFormsRendererRegistryEntry, rankWith, isBooleanControl } from "@jsonforms/core"
import { Checkbox } from "antd"

/**
 * Including this until the jsonforms library is updated to support the visibility control
 */
export const BooleanRegistry: JsonFormsRendererRegistryEntry = {
  tester: rankWith(100, isBooleanControl),
  renderer: withJsonFormsControlProps(BooleanControl),
}

function BooleanControl(props: ControlProps) {
  return <>{props.visible && <Checkbox {...props}>{props.label}</Checkbox>}</>
}
