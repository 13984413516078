/* eslint-disable react-refresh/only-export-components */ // FIXME
import styled from "styled-components"
import { useCallback } from "react"
import { Icon, IconTypes } from "src/ui/Icon"
import { App, Badge, Flex, MenuProps, Tag, Tooltip } from "antd"
import { useQuery } from "@apollo/client"

import { AppLink } from "src/ui/AppLink/AppLink"
import { submenuPopupClassName } from "src/ui/Menu/menuCSS"
import { useRequireRole } from "src/common/hooks/useRequireRole"
import { AGENT_INACTIVE_TOOLTIP, LOGS_PATH_NAME } from "src/GXAgent/GXAgent"
import { useIsGXAgentEnabled } from "src/common/hooks/useIsGXAgentEnabled"
import { ItemType, MenuItemType, SubMenuType } from "antd/es/menu/hooks/useItems"
import { useAgentStatus } from "src/common/hooks/useAgentStatus"
import { theme } from "src/ui/themes/theme"
import { DOCS_URL } from "src/common/config"
import { graphql } from "src/api/graphql"
import { SupportModalConfig } from "src/ui/Menu/SupportModal"
import { NODE_ENV } from "src/common/env"

type MenuItem = Required<MenuProps>["items"][number]
type AgentIconType = typeof ICON_NAME_INITIALIZING | typeof ICON_NAME_ACTIVE | typeof ICON_NAME_INACTIVE

export const SETTINGS_MENU_KEY = "settings"
const MENU_ICON_SIZE = "20px"
const ICON_NAME_ACTIVE = "cloudCheck"
const ICON_NAME_INACTIVE = "cloudTimes"
const ICON_NAME_INITIALIZING = "cloudQuestion"
const ICON_NAME_LOGS = "history"

const StyledLink = styled(AppLink)`
  && {
    color: inherit;
    &:hover,
    &:focus {
      color: inherit;
    }
  }
`

const assets = {
  key: "data-assets",
  label: <StyledLink to="data-assets">Data Assets</StyledLink>,
  icon: <Icon name="database" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
}

const createMenuItem = (
  key: string,
  disabled: boolean,
  tooltipTitle: string,
  linkTo: string,
  linkText: string,
  iconName: IconTypes,
): MenuItem => {
  return {
    key,
    disabled,
    label: (
      <Tooltip placement="right" title={disabled ? tooltipTitle : ""}>
        <Flex>
          <StyledLink disabled={disabled} to={linkTo}>
            {linkText}
          </StyledLink>
        </Flex>
      </Tooltip>
    ),
    icon: <Icon name={iconName} size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  }
}

const getLogs = (disabled: boolean) => {
  return createMenuItem("logs", disabled, "Logs", LOGS_PATH_NAME, "Logs", ICON_NAME_LOGS)
}

const getAgentMenuItem = (isAgentActive: boolean | undefined) => {
  let agentStatusText = "Initializing"
  if (isAgentActive !== undefined) {
    agentStatusText = isAgentActive ? "Active" : "Inactive"
  }

  let agentStatusBadgeColor: string = theme.colors.status.gxBadgeInitializing
  if (isAgentActive !== undefined) {
    agentStatusBadgeColor = isAgentActive ? theme.colors.status.gxBadgeActive : theme.colors.status.gxBadgeInactive
  }

  let iconName: AgentIconType = ICON_NAME_INITIALIZING
  let title: string | JSX.Element = "Initializing Agent"
  let toolTipEnabled = false
  if (isAgentActive !== undefined) {
    if (isAgentActive) {
      iconName = ICON_NAME_ACTIVE
      title = "Active Agent"
    } else {
      iconName = ICON_NAME_INACTIVE
      toolTipEnabled = true
      title = AGENT_INACTIVE_TOOLTIP
    }
  }
  const agentTag = (
    <Flex>
      <Tag color={agentStatusBadgeColor}>{agentStatusText} Agent</Tag>
    </Flex>
  )
  const label = toolTipEnabled ? (
    <Tooltip placement="right" title={title}>
      {agentTag}
    </Tooltip>
  ) : (
    agentTag
  )

  return {
    key: "agentStatus",
    label: label,
    icon: (
      <Badge color={agentStatusBadgeColor} dot offset={[-2, 4]} style={{ boxShadow: "none" }}>
        <Icon
          name={iconName}
          color={theme.colors.neutralColorPalette.whites.white}
          size={MENU_ICON_SIZE}
          className="anticon anticon-desktop ant-menu-item-icon"
        />
      </Badge>
    ),
    disabled: true,
    // This menu item is not clickable
    style: { cursor: "default" },
  } satisfies ItemType
}

const settingsSubMenu = (withUsers: boolean, withTokens: boolean, onTitleClick: SubMenuType["onTitleClick"]) => {
  const children: MenuItemType[] = []

  if (withUsers) {
    children.push(users)
  }
  if (withTokens) {
    children.push(tokens)
  }

  return {
    key: SETTINGS_MENU_KEY,
    label: "Settings",
    icon: <Icon name="settings" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
    children,
    popupClassName: submenuPopupClassName,
    onTitleClick,
  }
}

const users = {
  key: "users",
  label: <StyledLink to="users">Users</StyledLink>,
}

const tokens = {
  key: "tokens",
  label: <StyledLink to="tokens">Tokens</StyledLink>,
}

const docs = {
  key: "documentation",
  label: <StyledLink to={DOCS_URL}>Documentation</StyledLink>,
  icon: <Icon name="file" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
}

const supportMenuItem = {
  key: "support",
  title: "Support",
  icon: <Icon name="questionCircle" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  label: "Support",
}

const ff = {
  key: "_ff",
  title: "Feature Flags",
  icon: <Icon name="toggleOn" size={MENU_ICON_SIZE} className="anticon anticon-desktop ant-menu-item-icon" />,
  label: <StyledLink to="_ff">Feature Flags</StyledLink>,
}

interface useMenuItemsProps {
  toggleSettings: () => void
}

interface useMenuItemsReturnProps {
  topMenuItems: MenuItem[]
  bottomMenuItems: MenuItem[]
}

export const AllDataSourcesDocument = graphql(`
  query allDatasources {
    datasourcesV2 {
      id
    }
  }
`)

function useMenuItems({ toggleSettings }: useMenuItemsProps): useMenuItemsReturnProps {
  const { modal } = App.useApp()
  const supportMenuItemOnClick = useCallback(() => modal.info(SupportModalConfig), [modal])
  const showUsers = useRequireRole("ORGANIZATION_ADMIN")
  const showTokens = useRequireRole("EDITOR")
  const isViewer = useRequireRole("VIEWER", true)
  const isGXAgentEnabled = useIsGXAgentEnabled()
  const isAgentActive = useAgentStatus()
  const { data: dataSourcesData, loading: dataSourcesLoading } = useQuery(AllDataSourcesDocument)
  const isDataSourcesEmpty = !dataSourcesLoading && dataSourcesData?.datasourcesV2.length === 0

  return {
    topMenuItems: [
      assets,
      getLogs(isDataSourcesEmpty || dataSourcesLoading),
      ...(isViewer ? [] : [settingsSubMenu(showUsers, showTokens, toggleSettings)]),
    ],
    bottomMenuItems: [
      ...(isGXAgentEnabled ? [getAgentMenuItem(isAgentActive)] : []),
      docs,
      { ...supportMenuItem, onClick: supportMenuItemOnClick },
      ...(NODE_ENV === "development" ? [ff] : []),
    ],
  }
}

export { useMenuItems }
